export let requestErrors = { message: "Something was wrong. Try again", type: "warning" };

export const changeError = (props) => {
  console.log({ changeErrorProps: props });

  if (props.message) requestErrors = { ...requestErrors, message: props.message, type: "warning" };

  let data = props?.response?.data;

  console.log({ errrrrror: data });

  if (!data) return requestErrors;
  let convertFormat = requestErrors;
  let errors = data?.errors;
  let message = data?.message;
  // const hasStatus = [0, 1, 200, 201, 204, 401, 403, 404, 405, 406, 409, 415, 422, 440, 498, 500]
  if (errors)
    data?.errors?.map((error) => {
      let key = Object.keys(error)[0];
      let value = Object.values(error)[0];
      convertFormat = {
        ...convertFormat,
        [key]: {
          message:
            // key + " " +
            value,
        },
      };
      return convertFormat;
    });
  else if (message) convertFormat = { ...convertFormat, message: message };

  if (Object.keys(convertFormat).length) return convertFormat;
  else return requestErrors;
};
