import FieldText from "components/FieldText";
import styles from "./index.module.scss";
import { useQueryCustom, useQueryCustomWithoutURL } from "utils/useQueryCustom";
import axiosClient from 'lib/axiosConfig';
import api from "operations/network/api";
import EmojiPicker from "emoji-picker-react";
import Loading from "components/Loading";
import SelectFileComponent from "components/SelectFileComponent";
import { useState } from "react";
import ModalLayout from "components/ModalLayout";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import FieldSelect from "components/FieldSelect";
import { useEffect } from "react";
import { useMutationCustom } from "utils/useMutationCustom";
import { Button } from "react-bootstrap";
import { useQueryClient } from "react-query";



const TranslateComponent = ({ text, handleCancel, handleAccepted, languages }) => {

    const [translateText, setTranslateText] = useState()

    const gettingTranslate = async (params) => await axiosClient().get(`${api["translate"].base}`, { params });
    // // const gettingLanguages = async (params) => await axiosClient().get(`${api["translate"].base}/languages`, params);


    // const languagesRequest = queryClient.getQueryData("get_languages");


    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
    } = useForm({
        initial: { [fieldNames.text]: text },
        resolver: yupResolver(validation(text)),
        mode: "onSubmit",

    });


    const onSuccessMutating = (data) => {
        console.log("* * * onSuccessMutating :", { data });

        setValue(fieldNames.translatedText, data?.data?.data)
        setTranslateText(data?.data?.data)
        // toastify.success({ title: "success" });
        // navigation(newRoute.base);
    };
    const { isLoading, data, mutate } = useMutationCustom({
        url: gettingTranslate,
        onSuccess: onSuccessMutating,
    });

    const onSubmit = () => {
        const values = getValues()
        console.log("* * * onsubmit :", { values });
        const resData = {
            text: values?.text || text,
            language: values?.language?.value
        }
        mutate(resData)

    }

    useEffect(() => {
        setValue(fieldNames.text, text)
    }, [text])

    console.log("* * * TranslateComponent data :", { data });
    console.log("* * * TranslateComponent languages :", { languages });
    return (
        <div className={styles['container']}>
            <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
                {!text ?
                    <FieldText
                        require
                        register={register}
                        name={fieldNames.text}
                        placeholder={"text"}
                        label={"Text"}
                        errors={errors}
                    />
                    : ""}

                <FieldSelect
                    require
                    {...{
                        name: fieldNames.language,
                        register,
                        label: "Language",
                        errors,
                        control,
                        options: languages,
                    }}
                />

                <FieldText
                    disabled={!translateText}
                    require
                    register={register}
                    name={fieldNames.translatedText}
                    placeholder={"Translated"}
                    label={"Translated"}
                    errors={errors}
                />

                <div className="d-flex">

                    <Button block type="button" className={`btn btn-warning btn-sm`} disabled={isLoading} onClick={handleCancel} >
                        {"Cancel"}
                    </Button>
                    <div className="mr-2"></div>
                    <Button block type="submit" className={`btn btn-success btn-sm`} disabled={isLoading}>
                        {!isLoading ? "Translate" : "Loading..."}
                    </Button>

                    <div className="mr-auto"></div>
                    <Button block type="button" className={`btn btn-info btn-sm`} onClick={() => handleAccepted(translateText)} disabled={!translateText}>
                        {"done"}
                    </Button>
                </div>

            </form>

        </div>
    )
}
export default TranslateComponent