import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "operations/routing/routes";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import action from "./redux/store/action";
import logo from "assets/images/logo.png";
import FieldText from "components/FieldText";
import Spinner from "components/Spinner";
import AlertComponent from "components/AlertComponent";
import Loading from "components/Loading";

const Verify = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.verify);

  console.log(state, 'state verify data -----');

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onSubmit",
  });

  const onSubmit = () => {
    const values = getValues();
    dispatch(action.request({ code: values.code, email: state?.verifyData?.email, verifyToken: state?.verifyData?.verifyToken }));
  };

  console.log({ errors, state }, "Verify");

  useEffect(() => {
    if (state.data) navigate(routes.dashboard);
    else if (!state.verifyData) navigate(routes.login);
  }, [state]);

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo d-flex align-items-center gap-1">
              <img src={logo} alt="logo" style={{ width: 42 }} />
              <div className="h2 font-weight-bold mb-0">Chat</div>
              {/* <div className="logo-text text-center">
                <h2>Welcome to</h2>
                <h2>
                  <span className="text-primary">NEXPAT</span> dashboard
                </h2>
              </div> */}
            </div>

            <h6 className="font-weight-light">The code has been sent to your email.</h6>

            <Form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              <AlertComponent error={state.errorMessage} />
              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.code,
                  register,
                  type: "code",
                  placeholder: "X X X X X",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />

              <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={state?.isFetching}>{state?.isFetching ? <Loading size={"sm"} /> : "Verify Code"}</button>
              </div>

              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <div className="new-account mt-3">
                    <p className="">
                      Wrong email?{" "}
                      <Link className="text-primary" to={routes.login}>
                        {"Login"}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div class="footerLogin">
        <div class="copyright">
          {/* <p>
            Copyright © 2023{" "}
            <a href="https://www.datingpanel.net/" target="_blank">
              {" "}
              Dating Panel
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Verify;
