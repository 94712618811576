import WithErrorHandler from "components/WithErrorHandler";
import Login from "containers/Login";
import Verify from "containers/Verify";
import routes from "operations/routing/routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import adminAction from "redux/store/admin/action";
import actionRefreshToken from "redux/store/refreshToken/action";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import socketAction from "redux/store/socket/action";

const App = () => {
  const refreshTokenState = useSelector((state) => state.refreshToken);
  const accessTokenState = useSelector((state) => state.accessToken);
  const adminState = useSelector((state) => state.admin);
  const network = useSelector((state) => state.network);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(actionRefreshToken.refreshTokenCheck(null)); //null
  }, [dispatch]);

  useEffect(() => {
    if (accessTokenState.data) {
      dispatch(adminAction.request());
    }
  }, []);
  useEffect(() => {
    if (adminState?.data) dispatch(socketAction.request());
  }, [adminState])
  // useEffect(() => {
  //   dispatch(actionAccessToken.request());
  // }, []);

  console.log({ adminState }, "----> adminState");

  return (
    <WithErrorHandler>
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.verify} element={<Verify />} />
        <Route path={"*"} element={<AppRoutes />} />
      </Routes>
    </WithErrorHandler>
  );
};

export default App;
