import moment from 'moment';

function formatTimeAgo(timestamp) {
    if (!timestamp) timestamp = new Date();
    const now = moment();
    const postTime = moment(timestamp);
    const diffInHours = now.diff(postTime, 'hours');

    if (diffInHours < 1) {
        const diffInMinutes = now.diff(postTime, 'minutes');
        return diffInMinutes <= 0 ? 'now' : `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (diffInHours < 720) { // Up to 30 days
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    } else {
        const diffInMonths = Math.floor(diffInHours / 720);
        return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    }
}

export default formatTimeAgo;