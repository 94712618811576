import { yupResolver } from "@hookform/resolvers/yup";
import logo from "assets/images/logo.png";
import AlertComponent from "components/AlertComponent";
import FieldText from "components/FieldText";
import Loading from "components/Loading";
import routes from "operations/routing/routes";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import action from "./redux/store/action";
import { fieldNames, validation } from "./validation";
import actionUser from 'redux/store/admin/action';

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.login);
  const verifyState = useSelector((state) => state.verify);

  console.log({ state }, 'state login');

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onSubmit",
  });

  const onSubmit = () => {
    const values = getValues();
    console.log({ values });
    dispatch(action.request(values));
  };

  useEffect(() => {
    if (state?.data) navigate(routes.verify);
  }, [state]);

  useEffect(() => {
    dispatch(actionUser.logout());
    dispatch(action.initial());
  }, []);

  // console.log({ errors, state }, "login errors");

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo d-flex align-items-center gap-1">
              <img src={logo} alt="logo" style={{ width: 42 }} />
              <div className="h2 font-weight-bold mb-0">Dating Panel</div>
              {/* <div className="logo-text text-center">
                <h2>Welcome to</h2>
                <h2>
                  <span className="text-primary">Diolaunch</span> dashboard
                </h2>
              </div> */}
            </div>
            {/* <h6 className="font-weight-light text-center">Sign in to continue</h6> */}
            <Form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              {/* <Form.Group className="mb-3 d-flex search-field"> */}
              <AlertComponent error={state.errorMessage} />
              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.email,
                  register,
                  type: "email",
                  placeholder: "Email",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />
              {/* <Form.Control {...register(fieldNames.email)} type="email" placeholder="Username" size="lg" className="h-auto" /> */}
              {/* </Form.Group> */}
              {/* <Form.Group className="mb-3 d-flex search-field">
                <Form.Control {...register(fieldNames.password)} type="password" placeholder="Password" size="lg" className="h-auto" />
              </Form.Group> */}
              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.password,
                  register,
                  type: "password",
                  placeholder: "Password",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />

              {/* <div className="form-check">
                <label className="form-check-label text-muted">
                  <input type="checkbox" className="form-check-input" {...register(fieldNames.rememberMe)} />
                  <i className="input-helper"></i>
                  Remember me
                </label>
              </div> */}
              <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={state?.isFetching}>{state?.isFetching ? <Loading size={"sm"} /> : "SIGN IN"}</button>
              </div>
              {/* <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                </div>
                <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
              </div> */}
            </Form>
          </div>
        </div>
      </div>
      <div class="footerLogin">
        <div class="copyright">
          <p>
            Copyright © 2023{" "}
            <a href="https://www.datingpanel.net/" target="_blank">
              {" "}
              Dating Panel
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
