import * as actionTypes from "./actionTypes";

export default {
  request: (params, ctx) => ({
    type: actionTypes.USER_REQUEST,
    params,
    ctx,
  }),
  receive: (data) => ({
    type: actionTypes.USER_RECEIVE,
    data,
  }),
  initial: () => ({
    type: actionTypes.USER_INITIAL,
  }),
  setToken: (token) => ({
    type: actionTypes.USER_SET_TOKEN,
    token,
  }),
  login: (data) => ({
    type: actionTypes.USER_LOGIN,
    data,
  }),
  logout: (ctx) => ({
    type: actionTypes.USER_LOGOUT,
    ctx,
  }),
  changeInfo: (params) => ({
    type: actionTypes.USER_CHANGE_INFO,
    params,
  }),
  failure: (errorMessage) => ({
    type: actionTypes.USER_FAILURE,
    errorMessage,
  }),
};
