import nookies from "nookies";
import initialState from "./initialState";

export const requestFunc = (state, { queryOptions }) => ({
  ...state,
  isFetching: true,
  queryOptions,
});

export const receiveFunc = (state, { data }) => ({
  ...state,
  isFetching: false,
  errorMessage: null,
  data,
});

export const failureFunc = (state, { errorMessage }) => ({
  ...state,
  isFetching: false,
  errorMessage,
});

export const initialFunc = (ctx) => {
  // destroyCookie(null, 'accessToken');
  nookies.destroy(ctx, "accessToken", { path: "/" });
  return { ...initialState };
};
