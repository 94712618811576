import development from "configs/development.json";
import production from "configs/production.json";

const environment = process.env.REACT_APP_APP_STAGE === "production" ? production : development;

export default {
  host: environment.BASE_URL,
  auth: {
    login: "/auth/login-chat-panel",
  },
  refreshToken: "/auth/refresh-auth",
  accessToken: "/auth/refresh-auth",
  verify: "/auth/verify-login",
  network: "/network",
  dashboard: "/dashboard",
  current: "/admin/me",


  // Ticket
  ticket: {
    base: "/ticket",
    list: "/ticket",
    get: "/ticket",
  },
  translate: {
    base: "/translate",
    list: "/translate",
    get: "/translate",
  },
  // Chat
  chat: {
    base: "/chat",
    list: "/chat",
    get: "/chat",
  },

  // -------------------------------------------------- user
  user: {
    base: "/user",
    list: "/user",
    get: "/user",
    transaction: "/transaction",
    subscription: "/subscription",
    file: "/file",
    activity: "/activity",
    gallery: "/gallery",
    changeStatus: "/user/change-status",
    changePassword: "/account/edit-password",
  },

  // -------------------------------------------------- agency
  agency: {
    base: "/agency",
    list: "/agency",
    get: "/agency",
  },

  // AI Bot
  ai_character: {

    base: "/character",
    list: "/character",
    get: "/character",
    // category:{},
    // setting:{},
  },

  // Settings
  settings: {
    base: "/setting",
    list: "/setting",
    get: "/setting",
  },

  // Plan
  plan: {
    base: "/plan",
    list: "/plan",
    get: "/plan",
  },

  // Subscription
  subscription: {
    base: "/subscription",
    list: "/subscription",
    get: "/subscription",
  },

  // Redeem
  redeem: {
    base: "/redeem",
    list: "/redeem",
    get: "/redeem",
  },

  // Payment
  payment: {
    base: "/payment",
    list: "/payment",
    get: "/payment",
  },

  // Interest
  interest: {
    base: "/interest",
    list: "/interest",
    get: "/interest",
  },

  // Promotion
  promotion: {
    base: "/promotion",
    list: "/promotion",
    get: "/promotion",
  },

  // Transaction
  transaction: {
    base: "/transaction",
    list: "/transaction",
    get: "/transaction",
  },

  // Activity
  activity: {
    base: "/activity",
    list: "/activity",
    get: "/activity",
  },

  // call
  call: {
    base: "/call",
    list: "/call",
    get: "/call",
  },

  // faq
  faq: {
    base: "/faq",
    list: "/faq",
    get: "/faq",
  },

  // Product
  product: {
    base: "/product",
    list: "/product",
    get: "/product",
    add: "/add",
    edit: "/edit",
  },

  // Gallery
  gallery: {
    base: "/gallery",
    list: "/gallery",
    get: "/gallery",
  },

  // Files
  file: {
    base: "/file",
    list: "/file",
    get: "/file",
  },

  // permissions
  permission: {
    base: "/permission",
    list: "/permission",
    get: "/permission",
  },

  // notification
  notification: {
    base: "/notification",
    list: "/notification",
    get: "/notification",
  },

  // -------------------------------------------------- user assigned token
  assignedToken: {
    base: "/assigned-token",
    list: "/assigned-token/list",
    get: "/assigned-token/by-id",
  },

  // admin/manager
  manager: {
    base: "/admin",
    list: "/admin",
    get: "/admin",
    changeStatus: "/admin",
  },

  // --------------------------------------------------role
  role: {
    base: "/role",
    list: "/role",
    get: "/role",
    // changeStatus: "/role/change-status",
  },

  // -------------------------------------------------- newsletter
  newsletter: {
    base: "/admin/newsletter",
    list: "/admin/newsletter/list",
    get: "/admin/newsletter/get",
    changeStatus: "/admin/newsletter/change-status",
    public: "/newsletter",
    export: "/admin/newsletter/export",
  },

  // ------------------------------------------------- blogs
  category: {
    base: "/admin/blog-category",
    list: "/admin/blog-category/list",
    get: "/admin/blog-category/by-id",
    changeStatus: "/admin/blog-category/change-status",
  },
  post: {
    base: "/admin/blog",
    list: "/admin/blog/list",
    get: "/admin/blog/by-id",
    changeStatus: "/admin/blog/change-status",
  },

  // ------------------------------------------------- collection
  collectionCategory: {
    base: "/admin/collection-category",
    list: "/admin/collection-category/list",
    get: "/admin/collection-category/by-id",
    changeStatus: "/admin/collection-category/change-status",
  },

  collection: {
    base: "/admin/collection",
    list: "/admin/collection/list",
    get: "/admin/collection/by-id",
    changeStatus: "/admin/collection/change-status",
  },
  // ------------------------------------------------- nft
  nft: {
    base: "admin/nft",
    list: "admin/nft/list",
    get: "admin/nft/by-id",
    changeTrendStatus: "admin/nft/change-trend-status",
    changeStatus: "admin/nft/change-status",
  },

  marketplace: {
    base: "admin/nft/marketplace",
    list: "admin/nft/marketplace-list",
  },

  auctionList: {
    base: "admin/nft/auction",
    list: "admin/nft/auction-list",
    get: "admin/nft/auction-by-id",
  },
  auctionOffer: {
    base: "admin/offer",
    list: "admin/offer/list",
    get: "admin/offer/by-id",
    changeStatus: "admin/offer/change-status",
  },

  // ------------------------------------------------- media
  folder: {
    base: "/admin/media/folder",
    list: "/admin/media/folder/list",
    get: "/admin/media/folder/get",
    getBySlug: "/admin/media/folder/get/slug",
    changeStatus: "/admin/media/folder/change-status",
  },
  // gallery: {
  //   base: "/gallery",
  //   list: "/gallery",
  //   get: "/gallery",
  //   changeStatus: "/gallery/change-status",
  // },
};
