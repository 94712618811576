import FieldText from "components/FieldText";
import toastify from "lib/toastify";
import { panelSocket } from "operations/network/socket";
import { memo, useEffect, useRef } from "react";
import { useState } from "react";
import { Badge, Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import axiosClient from "lib/axiosConfig";
import api from "operations/network/api";
import { API_NAME } from "../enum";
import { PLACEHOLDER_IMAGE } from "enumerations";
import Loading from "components/Loading";
import EmojiPicker from "emoji-picker-react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Link, Router } from "react-router-dom";
import routes from "operations/routing/routes";
import { formatISO } from "date-fns";
import moment from 'moment';
import { useQueryCustom } from "utils/useQueryCustom";
import styles from "../chat/index.module.scss"

const Note = ({ ChatId, user, data, isLoading, refetch }) => {

    const [toggleEdit, setToggleEdit] = useState(false);
    const [SendTextLoading, setSendTextLoading] = useState(false);

    const [ChatsList, setChatsList] = useState([]);

    const messageInputRef = useRef()
    const handleSortChatList = (data) => {
        console.log("* * * handleSuccessGetData Note: ", { data });
        // return (data?.sort((a, b) => a?.createdAt > b?.createdAt))
    }

    const handleSuccessGetData = ({ data }) => {
        console.log("* * * handleSuccessGetData Note: ", { data });
        // const sorted = handleSortChatList(data?.data?.results?.reverse())
        // console.log("* * * handleSuccessGetData: ", { sorted });

        // setChatsList(data?.data?.results?.reverse())
    }

    // const getting = async () => await axiosClient().get("chat/note", {
    //     params: {
    //         chat: ChatId,
    //         user: user?._id,
    //         // users
    //     },
    // });

    // const {
    //     data: { data } = {},
    //     error,
    //     isError,
    //     isLoading,
    //     refetch,

    // } = useQueryCustom({
    //     name: `${API_NAME}_note_get_${user?._id}`,
    //     url: getting,
    //     enabled: !!user,
    //     onSuccess: handleSuccessGetData,
    //     params: {
    //         chat: ChatId,
    //         user: user?._id,
    //     },
    // });



    const handleToggleEdit = () => {
        setToggleEdit(p => !p)
    }

    const handleCancelEdit = () => {
        if (data?.note) messageInputRef.current.value = data?.note
        setToggleEdit(p => !p)
    }

    const handleEditNote = () => {
        // if (!messageInputRef?.current?.value?.trim()) return;
        if (!messageInputRef?.current?.value == data?.note) return;
        setSendTextLoading(true)

        axiosClient().post("chat/note", {
            chat: ChatId,
            user: data?.user?._id,
            note: messageInputRef.current.value
        }).then(({ data }) => {

            console.log({ data });
            toast.success("note edited successfully!");
            setSendTextLoading(false)
            handleToggleEdit()
            refetch();

        }).catch((err) => {
            toast.error(err?.message);
            console.log({ err })
            setSendTextLoading(false)
        })
    }

    console.log(`* * * note Data ${data?.user?._id}: `, { data });

    return (
        <div className="chat-note-side">
            <div className="chatInfoMainBox">
                <div className="d-flex customSiteTitle justify-content-between">
                    <div className="d-flex align-items-center">

                        <b>{`${data?.user?.name || ""} note`}</b>
                    </div>

                    {!toggleEdit ? <Badge bg="primary" className="d-flex cursor-pointer py-2 rounded-pill" onClick={handleToggleEdit}>
                        <i className="mdi mdi-pencil mr-1"></i>
                        <span>
                            Edit
                        </span>
                    </Badge> :
                        <div className="d-flex">
                            {!SendTextLoading ? <Badge bg="warning" className="d-flex cursor-pointer py-2 rounded-pill mr-2" onClick={handleCancelEdit}>
                                <span>
                                    Cancel
                                </span>
                            </Badge> : ""}
                            <Badge bg="success" className="d-flex cursor-pointer  py-2 rounded-pill" onClick={!SendTextLoading && handleEditNote} disabled={SendTextLoading}>
                                {SendTextLoading ?
                                    <Loading animation="border" size="sm" />
                                    :
                                    <>
                                        <i className="mdi mdi-pencil mr-1"></i>
                                        <span >
                                            {"Save"}
                                        </span>
                                    </>
                                }

                            </Badge>
                        </div>

                    }
                </div>
                {
                    // toggleEdit ?
                    //     //  <FieldText
                    //     //     className={"textMessageInput"}
                    //     //     type={"text"}
                    //     //     placeholder={"Note"}
                    //     //     as={"textarea"}
                    //     //     // row={MessageInputRow}
                    //     //     refrence={messageInputRef}
                    //     //     parentClass={"h-100 mb-0"}
                    //     //     style={{
                    //     //         // minHeight: "81px",
                    //     //         height: "100%",
                    //     //     }}
                    //     // // onKeyUp={(e) => handleCheckKeyPress(e)}
                    //     // // onChange={(e) => setChatNoteInfo(e.target.value)}
                    //     // // value={ChatNoteInfo}
                    //     // /> 
                    //     <Form.Control as="textarea" className={["h-100",styles.disabled].join(" ")} ref={messageInputRef} defaultValue={data?.data?.note || ""} />
                    //     : 
                    <div className="singleChatNoteMainBody">


                        {/* {data?.data ? */}
                        <Form.Control
                            as="textarea"
                            className={["h-100", !toggleEdit && styles.disabled].join(" ")}
                            ref={messageInputRef}
                            defaultValue={data?.note || ""}
                            disabled={!toggleEdit}
                            placeholder={isLoading ? "loading . . ." : "No Nots !"}
                        />
                        {/* : <div className="h6 text-center text-content mt-2"> No Nots !</div>
                        } */}

                    </div>
                }


            </div>

            {/* <div className="chatInfoNoteText">

                <div className="chatInfoMainBoxFooter">
                    <FieldText
                        className={"textMessageInput"}
                        type={"text"}
                        placeholder={"Note"}
                        as={"textarea"}
                        // row={MessageInputRow}
                        refrence={messageInputRef}
                        // style={{
                        //     // minHeight: "81px",
                        //     // height: MessageInputRow * 10 + "px",
                        // }}
                    // onKeyUp={(e) => handleCheckKeyPress(e)}
                    // onChange={(e) => setChatNoteInfo(e.target.value)}
                    // value={ChatNoteInfo}
                    />

                    <div className="chatInfoMainBoxFooterButton" onClick={handleEditNote}>
                        {SendTextLoading ? <Loading /> : "Send"}
                    </div>
                </div>

            </div> */}
        </div>
    )
}

export default memo(Note)