import { put, call, takeEvery, all, delay } from "redux-saga/effects";
import { changeError } from "utils/errors";
import actionTypes from "../actionTypes";
import action from "../store/action";
import actionAccessToken from "redux/store/accessToken/action";
import actionRefreshToken from "redux/store/refreshToken/action";
import network from "lib/network";
import { tokenMsAge } from "utils/cookies";
import nookies, { setCookie } from "nookies";
import api from "operations/network/api";
import axiosConfig from "lib/axiosConfig";
import actionUser from "redux/store/admin/action";
import {addDays} from "date-fns/fp";

// use for demo
// let response = {
//   message:'ok',
//   data:{
//     refreshToken: 'xxxxxx',
//     refreshToken: 'xxxxxx'
//   }
// }

function* postSaga({ params }) {
  console.log({ params });
  // TODO; add result after res.data
  try {
    const res = yield call(network.post, { url: api.verify, params });
    // const res = yield call(axiosConfig.post, { url: api.verify, params });
    console.log({ res }, "after verify res");

    let accessToken = res.data?.data?.access?.token;
    let refreshToken = res.data?.data?.refresh?.token;
    const dateNow = new Date();
    let exTimeAccess = new Date(res.data?.data?.access?.expires);
    let exTimeRefresh = new Date(res.data?.data?.refresh?.expires);
    // let exTimeRefresh = Math.abs(Math.floor(tokenMsAge(refreshToken)?.s));

    console.log({ exTimeAccess, exTimeRefresh, dateNow , refreshToken });

    yield setCookie(null, "refreshToken", refreshToken, { maxAge: exTimeRefresh, path: "/" });
    yield setCookie(null, "accessToken", accessToken, { path: "/" });
    // yield nookies.set(ctx, 'refreshToken', refreshToken, { maxAge: exTimeRefresh })
    // yield nookies.set(ctx, 'accessToken', accessToken)
    // , { maxAge: exTimeAccess }

    yield put(actionAccessToken.receive(accessToken));
    yield put(actionRefreshToken.receive(refreshToken));

    yield put(actionUser.request());

    yield put(action.receive(res.data));

    yield delay(2000);
    yield put(action.initial());
    // yield put(actionUser.initial());
  } catch (error) {
    yield put(action.failure(changeError(error)));
    yield delay(2000);
    yield put(action.failure());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.VERIFY_CODE_REQUEST, postSaga)]);
}
