import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import actionUser from "redux/store/admin/action";
import toastify from "lib/toastify";

const WithErrorHandler = ({ children }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const reqInterceptor = axios.interceptors.request.use((req) => {
    setError(null);
    return req;
  });

  const resInterceptor = axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      setError(err);
      throw err;
      // return err
    }
  );

  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [reqInterceptor, resInterceptor]);

  console.log({ WithErrorHandler: error });

  useEffect(() => {
    if (error)
      if (error?.message === "Network Error") {
        toastify.error({ title: "Network Error" });
      } else if (error?.response?.status == 401) {
        dispatch(actionUser.logout());
      } else if (error?.response?.status == 403) {
        // dispatch(actionUser.logout());
        // toastify.error({ title: `${error?.response?.status} - ${error?.response?.data?.errors[0]?.message}` });
      } else if (error?.response?.status == 404) {
        // dispatch(actionUser.logout());
      } else {
        // toastify.error({ title: `${error?.response?.status} - ${error?.response?.data?.errors[0]?.message}` });
      }
  }, [error]);

  return children;
};

export default WithErrorHandler;
