import { NETWORKS_LOGO } from "enumerations";

export const networkTypes = {
  ETH_MAINNET: "ETH_MAINNET",
  AVAX: "AVAX",
  BSC_TESTNET: "BSC_TESTNET",
  BSC_MAINNET: "BSC_MAINNET",
  ETH_MAINNET: "ETH_MAINNET",
  MATIC_TESTNET: "MATIC_TESTNET",
  MATIC_MAINNET: "MATIC_MAINNET",
  KCC_TESTNET: "KCC_TESTNET",
  KCC_MAINNET: "KCC_MAINNET",
  FTM: "FTM",
};

const networks = {
  [networkTypes.MATIC_MAINNET]: {
    name: "MATIC MAINNET",
    image: NETWORKS_LOGO.Matic,
    title: "Matic(Polygon) Mainnet",
    network: {
      chainId: 137,
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
  },
  [networkTypes.BSC_MAINNET]: {
    name: "BSC MAINNET",
    image: NETWORKS_LOGO.BSC,
    title: "BNB Smart Chain Mainnet",
    network: {
      chainId: 56,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org",
      ],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  },
  [networkTypes.ETH_MAINNET]: {
    name: "ETH MAINNET",
    image: NETWORKS_LOGO.ETH,
    title: "Ethereum Mainnet",
    network: {
      chainId: 1,
      chainName: "Ethereum Mainnet",
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [
        "https://mainnet.infura.io/v3/0768ee66b93c4153922b092166611422",
        "wss://mainnet.infura.io/ws/v3/0768ee66b93c4153922b092166611422",
        "https://api.mycryptoapi.com/eth",
        "https://cloudflare-eth.com",
      ],
      blockExplorerUrls: ["https://etherscan.io"],
    },
  },

  // [networkTypes.KCC_MAINNET]: {
  //     name: "KCC MAINNET",
  //     network: {
  //         chainId: 321,
  //         chainName: "KCC Mainnet",
  //         nativeCurrency: {
  //             "name": "KuCoin Token",
  //             "symbol": "KCS",
  //             "decimals": 18
  //         },
  //         rpcUrls: [
  //             "https://rpc-mainnet.kcc.network",
  //             "wss://rpc-ws-mainnet.kcc.network"
  //         ],
  //         blockExplorerUrls: ["https://kcc.io"]
  //     }
  // },
  // cchain: {
  //     name: "AVAX",
  //     network: {
  //         chainId: 43114,
  //         chainName: "Avalanche C-Chain",
  //         nativeCurrency: {
  //             "name": "Avalanche",
  //             "symbol": "AVAX",
  //             "decimals": 18
  //         },
  //         rpcUrls: [
  //             "https://api.avax.network/ext/bc/C/rpc"
  //         ],
  //         blockExplorerUrls: ["https://snowtrace.io"]
  //     }
  // },
  // fantom: {
  //     name: "Fantom",

  //     network: {
  //         chainId: 250,
  //         chainName: "Fantom Opera",
  //         nativeCurrency: {
  //             "name": "Fantom",
  //             "symbol": "FTM",
  //             "decimals": 18
  //         },
  //         rpcUrls: [
  //             "https://rpc.ftm.tools"
  //         ],
  //         blockExplorerUrls: ["https://ftmscan.com"]
  //     }
  // },
  [networkTypes.BSC_TESTNET]: {
    name: "BSC TESTNET",
    image: NETWORKS_LOGO.BSC,
    title: "BNB Smart Chain Testnet",
    testnet: true,
    network: {
      chainId: 97,
      chainName: "Binance Smart Chain Testnet",
      nativeCurrency: {
        "name": "Binance Chain Native Token",
        "symbol": "tBNB",
        "decimals": 18,
      },
      rpcUrls: [
        "https://data-seed-prebsc-1-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-1-s2.binance.org:8545",
        "https://data-seed-prebsc-2-s2.binance.org:8545",
        "https://data-seed-prebsc-1-s3.binance.org:8545",
        "https://data-seed-prebsc-2-s3.binance.org:8545",
      ],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
    },
  },
  [networkTypes.MATIC_TESTNET]: {
    name: "MATIC TESTNET",
    image: NETWORKS_LOGO.Matic,
    title: "Matic Testnet Mumbai",
    testnet: true,
    network: {
      chainId: 80001,
      chainName: "Mumbai",
      nativeCurrency: {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18,
      },
      rpcUrls: ["https://matic-mumbai.chainstacklabs.com", "https://rpc-mumbai.maticvigil.com", "https://matic-testnet-archive-rpc.bwarelabs.com"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    },
  },
};

export default networks;

// console.log({ injected });

export let networkChinIds = {};
export let networkArray = [];

for (const key in networks) {
  if (Object.hasOwnProperty.call(networks, key)) {
    const obj = networks[key];
    const param = { ...obj, key, value: obj.network.chainId, chainId: obj.network.chainId };
    networkChinIds[obj.network.chainId] = param;
    networkArray.push(param);
  }
}

export const chooseNetwork = {
  global: [{ ...networks[networkTypes.ETH_MAINNET] }, { ...networks[networkTypes.BSC_MAINNET] }, { ...networks[networkTypes.MATIC_MAINNET] }],
  testnet: [{ ...networks[networkTypes.BSC_TESTNET] }, { ...networks[networkTypes.MATIC_TESTNET] }],
};
