import { all, fork } from "redux-saga/effects";
// import signIn from 'containers/SignIn/redux/saga'
// import verifyCode from 'containers/VerifyCode/redux/saga'

import accessToken from "./saga/accessToken";
import refreshToken from "./saga/refreshToken";
import admin from "./saga/admin";
import verify from "containers/Verify/redux/saga";
import login from "containers/Login/redux/saga";
import socket from "./saga/socket";

function* watchers() {
  return yield all(
    [
      fork(login), fork(verify), fork(accessToken), fork(refreshToken), fork(admin),
      fork(socket),
    ]
  );
}

export default watchers;
