
import { ERRORS } from "enumerations";
import * as yup from 'yup';

export const fieldNames = {
    email: 'email',
    code: 'code',
    captcha: 'captcha'
};


export const validation = yup.object().shape({
    [fieldNames.code]: yup
        .string()
        .required(ERRORS.pleaseEnterYour('code'))
        .label('code')
})





