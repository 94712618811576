import React from "react";
import Spinner from "react-bootstrap/Spinner";
const Loading = ({ size, animation }) => {
  return (
    <Spinner animation={animation} role="status" size={size}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};
Loading.defaultProps = {
  size: "sm",
  animation: "border"
}
export default Loading;
