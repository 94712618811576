import { convertorArrayOption } from "utils/convertor";
import placeholder from "assets/images/placeholder.png";
import bsc from "assets/images/networks/BSC.png";
import eth from "assets/images/networks/ETH.svg";
import matic from "assets/images/networks/Matic.png";

export const LOCAL_STORAGE_TEMPLATE = {
  token: "token",
};

export const REGISTER_TYPE = {
  phone: "PHONE_NUMBER",
  email: "EMAIL",
};

export const ERRORS = {
  isRequire: (author) => `${author} is require`,
  isNotValid: (author) => `${author} is not valid`,
  pleaseEnterYour: (author) => `please enter your ${author}`,
  pleaseEnter: (author) => `please enter ${author}`,
  mustBe: (author, kind) => `${author} must be ${kind}`,
  pleaseSelectYour: (author) => `please select your ${author}`,
};

export const tableDisplayed = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 75, value: 75 },
  // { label: 80, value: 80 },
  // { label: 160, value: 160 },
  // { label: 240, value: 240 },
];

export const baseImageUrl = () => {
  return {
    main: "https://ds447a7iqja4o.cloudfront.net/images/",
    resized: "https://dx0uqd4klfo90.cloudfront.net/resized-images/",
  };
};

export const ONLINE_STATUSES = [
  { label: "online", value: "online", color: "success" },
  { label: "offline", value: "offline", color: "warning" },
];
export let ONLINE_STATUSES_OBJECT = {};

convertorArrayOption(ONLINE_STATUSES, ONLINE_STATUSES_OBJECT);

export const PRODUCT_TYPE = [
  // { label: "All Status", value: undefined },
  { label: "Coin", value: "coin" },
  { label: "Gift", value: "gift" },
  { label: "Subscription", value: "subscription" },
  { label: "Subscription gallery", value: "subscription_gallery" },
];

export const STATUSES = [
  // { label: "All Status", value: undefined },
  { label: "DISABLE", value: "disable" },
  { label: "ACTIVE", value: "active" },
  { label: "DELETE", value: "delete" },
  { label: "BLOCK", value: "block" },
  { label: "BAN", value: "ban" },
];
export const STATUSES_USER = [
  // { label: "All Status", value: undefined },
  { label: "PENDING", value: "pending" },
  { label: "ACTIVE", value: "active" },
  { label: "DELETE", value: "delete" },
  { label: "INACTIVE", value: "inactive" },
];
export const STATUSES_OFFER = [
  // { label: "All Status", value: undefined },
  { label: "CANCEL", value: "CANCEL" },
  { label: "REGISTER", value: "REGISTER" },
  { label: "ACCEPTED", value: "ACCEPTED" },
  // { label: "DENIED", value: "DENIED" },
];

export let STATUSES_OBJECT = {};

convertorArrayOption(STATUSES, STATUSES_OBJECT);

export const SORT_OPTION = [
  { label: "Newest", value: "createdAt:desc" },
  { label: "Oldest", value: "createdAt:asc" },
];

export const SORT_OPTION_OBJECT = {};

convertorArrayOption(SORT_OPTION, SORT_OPTION_OBJECT);

export const PLACEHOLDER_IMAGE = placeholder;

export const PERMISSIONS = {
  // -------------------------------
  USERS: [
    "GET_USER",
    "GET_USER_INFO",
    "UPDATE_USER",
    "DELETE_USER",
  ],
  PRODUCT: [
    "GET_PRODUCT",
    "CREATE_PRODUCT",
    "UPDATE_PRODUCT",
    "DELETE_PRODUCT",
  ],
  ACTIVITY: [
    "GET_ACTIVITY",
  ],
  BLOGS: "BLOGS",
  NEWSLETTERS: "NEWSLETTERS",
  COLLECTION_CATEGORY: "COLLECTION_CATEGORY",
  COLLECTION: "COLLECTION",
  ADMINISTRATOR: "ADMINISTRATOR",
  NFT: "NFT",
};
//  ----------------------------------------- COOKIE NAMES ----------------------------------------- //

export const cookie_names = {
  token: "token",
  language: "language",
  currency: "currency",
  refreshToken: "refresh_token",
  accessToken: "access_token",
  web3: "web3",
  account: "account",
  network: "network",
  clickedWallet: "clickedWallet",
};

export const NETWORKS_LOGO = {
  BSC: bsc,
  ETH: eth,
  Matic: matic,
};
