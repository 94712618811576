import actionVerifyCode from "containers/Verify/redux/store/action";
import { put, call, takeEvery, all, delay } from "redux-saga/effects";
import { changeError } from "utils/errors";
import actionTypes from "../actionTypes";
import action from "../store/action";
import network from "lib/network";
import api from "operations/network/api";
// /store/action


// user for demo
let response = {
  data: {
    "refreshToken": "string",
    "accessToken": "string",
    "user": {
      type:"administrator",
      fullName: "string",
      email: "string",
      password: "string",
      role: "admin"
    },
    "permissions": []
  },
  message:'success'
  
}
function* postSaga({ params }) {
  yield put(actionVerifyCode.initial());
  console.log({ params });
  try {
    const res = yield call(network.post, { url: api.auth.login, params });
    console.log({ res });
    yield put(actionVerifyCode.verifyData({ ...params, login: true , verifyToken :res?.data?.data?.verifyToken  }));
    yield put(action.receive(res.data));
    yield delay(2000);
    yield put(action.initial());
  } catch (error) {
    console.log({ error });
    yield put(action.failure(changeError(error)));
    yield delay(2000);
    yield put(action.initial());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LOGIN_REQUEST, postSaga)]);
}
