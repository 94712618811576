import actionTypes from "./actionTypes"

export default {
    request: (params, ctx) => ({
        type: actionTypes.ACCESS_TOKEN_REQUEST,
        params,
        ctx
    }),
    receive: (data, ctx) => ({
        type: actionTypes.ACCESS_TOKEN_RECEIVE,
        data,
        ctx
    }),
    initial: (ctx) => ({
        type: actionTypes.ACCESS_TOKEN_INITIAL,
        ctx
    }),
    failure: (errorMessage) => ({
        type: actionTypes.ACCESS_TOKEN_FAILURE,
        errorMessage
    }),
    checkAccessTokenTimeout: (expirationTime, ctx) => ({
        type: actionTypes.ACCESS_TOKEN_CHECK_TIMEOUT,
        expirationTime,
        ctx
    }),
    accessTokenCheck: (ctx) => ({
        type: actionTypes.ACCESS_TOKEN_CHECK,
        ctx
    }),
}