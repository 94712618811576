
import styles from "./index.style.module.scss"

const SelectFileComponent = ({ onChange }) => {

    const handleChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            onChange({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    return (
        <label className={styles.label}>
            <input type="file" hidden onChange={handleChange} />
            <span className={["mdi mdi-link-variant share-file", styles["btn-up"]].join(" ")}></span>
        </label>
    )
}

export default SelectFileComponent;