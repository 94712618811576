import Dashboard from "containers/Dashboard";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import routes from "./routes";

import ChangePassword from "containers/ChangePassword";
import ChatMainIndex from "containers/messages";

const Buttons = lazy(() => import("containers/basic-ui/Buttons"));
const Dropdowns = lazy(() => import("containers/basic-ui/Dropdowns"));
const Typography = lazy(() => import("containers/basic-ui/Typography"));
const BasicElements = lazy(() => import("containers/form-elements/BasicElements"));
const BasicTable = lazy(() => import("containers/tables/BasicTable"));
const Mdi = lazy(() => import("components/icons/Mdi"));
const ChartJs = lazy(() => import("containers/charts/ChartJs"));
const Error404 = lazy(() => import("containers/error-pages/Error404"));
const Error500 = lazy(() => import("containers/error-pages/Error500"));
const Lockscreen = lazy(() => import("containers/user-pages/Lockscreen"));
const BlankPage = lazy(() => import("containers/general-pages/BlankPage"));

// -------------------- containers

const routing = [
  { path: "/", exact: true, element: <Navigate to={routes.dashboard} /> },

  {
    path: routes.dashboard,
    element: <Dashboard />,
    // permissions: ["ALL"],
  },

  { path: routes.adminInfo, element: <ChangePassword /> },
  { path: routes.chat.all, element: <ChatMainIndex />, /*permissions: PERMISSIONS.USERS*/ },


  { path: "/basic-ui/buttons", element: <Buttons /> },
  { path: "/basic-ui/dropdowns", element: <Dropdowns /> },
  { path: "/basic-ui/typography", element: <Typography /> },
  { path: "/form-Elements/basic-elements", element: <BasicElements /> },
  { path: "/tables/basic-table", element: <BasicTable /> },
  { path: "/icons/mdi", element: <Mdi /> },
  { path: "/charts/chart-js", element: <ChartJs /> },
  { path: "/user-pages/lockscreen", element: <Lockscreen /> },
  { path: "/error-pages/error-404", element: <Error404 /> },
  { path: "/error-pages/error-500", element: <Error500 /> },
  { path: "/general-pages/blank-page", element: <BlankPage /> },
  // { path: "*", element: Error404 },
];

export default routing;
