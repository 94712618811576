import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import SingleChat from "./chat";
import List from "./list";

const ChatMainIndex = () => {
  const newRoute = useMemo(() => getDataInObjectDepth("chat", routes), []);
  return (
    <Routes>
      <Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
      <Route path={newRoute.list} element={<List />} />
      <Route path={`${newRoute.list}/:id`} element={<List />} />
      <Route path={`${newRoute.base}/:id`} element={<SingleChat />} />
    </Routes>
  );
};

export default ChatMainIndex;
