import { parseCookies, destroyCookie } from "nookies";
// import jwt from "jsonwebtoken";
import { decodeToken } from "react-jwt";

// export const decode = token => {
//     return jwt.decode(token, { complete: true });
// };

export const decodedToken = (token) => {
  const tokenDecoded = decodeToken(token);
  console.log({ tokenDecoded });
  return tokenDecoded;
  // .payload.exp
};

export function removeTokens() {
  destroyCookie(null, "accessToken", { path: "/" });
  destroyCookie(null, "refreshToken", { path: "/" });
}

export function getAccessToken() {
  return parseCookies().accessToken;
}

export function getRefreshToken() {
  return parseCookies().refreshToken;
}

export function getLanguage() {
  return parseCookies().language;
}

export function tokenMsAge(token) {
  const dateNow = Date.now() / 1000;
  let second = 0;
  let expTime = decodedToken(token)?.exp;
  if (expTime) second = Math.floor(expTime - dateNow);
  // if (expTime) second = expTime;

  console.log({ expTime, token });
  return {
    s: second,
    ms: second * 1000,
  };
}
