import React from "react";
import toastify from "lib/toastify";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import { changeError } from "utils/errors";



export function useQueryCustom({ name, url, params, onSuccess, enabled }) {
  const toastId = React.useRef(null);
  return useQuery([name, params ? params : { limit: 25, sort: "desc", page: 1 }], url, {
    onSuccess,
    onError: (error) => {
      const changedError = changeError(error);
      toast.update(toastId.current, { type: toast.TYPE.WARNING, autoClose: 5000, render: "Error" });
      Object.entries(changedError).map(([key, value]) => {
        return toastify.error({ title: value.message });
      });
    },
    enabled: enabled,
  });
}


