import axiosConfig from "lib/axiosConfig";
import { toast } from "react-toastify";
import swal from "sweetalert";

export const deleteFile = (id) => axiosConfig().delete(`/file/${id}`)

export const uploadFile = (data) => axiosConfig().post(`/file` , data)

export const handleRemoveFile = (file , refetch) => {

    let swalContent = document.createElement("div");
    swalContent.innerHTML = `<img src="${file.url}" class="swalModalImage" />`

    // let helpArr = DeletedFileArrayIds;

    // helpArr.push(file?._id);

    // setDeletedFileArrayIds(helpArr)

    // console.log({helpArr})

    swal({
        title: "Are you sure?",
        text: `You want to Delete this file`,
        icon: "error",
        content: swalContent,
        buttons: true,
        dangerMode: true,
    }).then((willBe) => {
        if (willBe) {
            deleteFile(file?._id).then(({ data }) => {
                if (refetch) {

                    refetch();
                }
                toast.success("File Deleted Successfully!")

            }).catch((err) => {
                console.log(err);
                toast.error(err.response.data.message || "Error")
            })
        }
    });
}

export const handleUploadFile = (file) => {

}