import { all, put, takeLatest, delay } from "@redux-saga/core/effects";
import { getRefreshToken, tokenMsAge } from "utils/cookies";
import action from "../store/refreshToken/action";
import actionUser from "redux/store/admin/action";
import actionTypes from "../store/refreshToken/actionTypes";
import actionAccessToken from "../store/accessToken/action";

// let timeout;
// const delay = ms => new Promise(resolve => {
//   resolve(setTimeout(() => {
//     console.log('timed-out')
//   }, ms))
// })

export function* checkAuthRefreshTokenTimeoutSaga({ expirationTime, ctx }) {
  console.log({ expirationTime }, "checkAuthRefreshTokenTimeoutSaga");
  // yield call(delay, expirationTime)
  // yield delay(String(expirationTime)); // 30 days
  yield delay(String(2147483647)); // 24 days max
  console.log({ expirationTime }, "refresh expirationTime actionUser.logout()");
  yield put(actionUser.logout(ctx));
}

export function* authCheckSaga({ ctx }) {
  // clearTimeout(timeout)
  const refreshToken = yield getRefreshToken(ctx);
  console.log({ refreshToken }, "authCheckSaga");
  if (!refreshToken) {
    console.log("authCheckSaga actionUser.logout()");
    yield put(actionUser.logout(ctx));
  } else {
    const expirationDate = yield tokenMsAge(refreshToken)?.ms;
    console.log({ expirationDate }, "tokens");
    let valid = expirationDate || expirationDate < 0;
    if (!valid) {
      console.log("authCheckSaga !valid actionUser.logout()");
      yield put(actionUser.logout(ctx));
    } else {
      yield put(action.checkRefreshTokenTimeout(expirationDate));
      yield put(actionAccessToken.accessTokenCheck(ctx));
    }
  }
}

function* rootSaga() {
  yield all([
    takeLatest(actionTypes.REFRESH_TOKEN_CHECK, authCheckSaga),
    takeLatest(actionTypes.REFRESH_TOKEN_RECEIVE, authCheckSaga),
    takeLatest(actionTypes.REFRESH_TOKEN_CHECK_TIMEOUT, checkAuthRefreshTokenTimeoutSaga),
  ]);
}

export default rootSaga;
