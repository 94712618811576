import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalLayout({ show, setShow, title, children }) {

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>

        </Modal>
    );
}

export default ModalLayout;