import React from 'react'
import "./index.scss"

const SplashScreen = ({ show }) => {

    return (
        <>
            <div className={`splash-screen ${show && "show"}`}>

                {/* Old Loading */}
                {/* <div className="boxes">
                    <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div> */}

                {/* New Loading */}
                <div class="panelLoading">
                    <span>C</span>
                    <span>H</span>
                    <span>A</span>
                    <span>T</span>
                </div>
            </div>


        </>

    )

}

export default SplashScreen;