import Spinner from "components/Spinner";
import routing from "operations/routing";
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import SettingsPanel from "components/SettingsPanel";
import Footer from "components/Footer";
import { useSelector, useDispatch } from "react-redux";
import routes from "operations/routing/routes";
import SplashScreen from "components/SplashScreen";
import ProtectedRoute from "components/ProtectedRoute";
import { findUserAccessPermission } from "utils/index";

const AppRoutes = () => {

  const otherStyleWrapperRoute = [
    routes.chat.base + routes.chat.list,
  ];

  let navigate = useNavigate();
  const location = useLocation();
  const refreshTokenState = useSelector((state) => state.refreshToken);
  const adminState = useSelector((state) => state.admin);

  let auth = refreshTokenState?.data;
  console.log({ auth });

  useEffect(() => {
    if (!auth) navigate(routes.login);
  });

  console.log("*** admin date", { adminState, show: adminState?.data });

  return auth ? (
    <>
      <SplashScreen show={!adminState?.data} />
      {adminState?.data ? (
        <>
          <Navbar />

          <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
              <Sidebar />
              <div className="main-panel">
                <div className={`content-wrapper
                `}>
                  {/* //  ${otherStyleWrapperRoute.includes(location.pathname) ? " p-0" : "" } */}

                  <Suspense fallback={<Spinner />}>
                    <Routes>
                      {routing.map((x) => (
                        <Route
                          key={x.path}
                          path={x.path}
                          exact={x?.exact}
                          element={
                            // x?.permissions ? <ProtectedRoute isAllowed={findElementsInTwoArrays(adminState.data.permissions, x.permissions)}>{x.element}</ProtectedRoute> : x.element
                            (adminState?.data?.role?.name === "administrator" || adminState?.data?.role?.name === "superadmin") ? (
                              x.element
                            ) : x?.permissions ? (
                              <ProtectedRoute isAllowed={findUserAccessPermission(adminState?.data?.role?.permissions, x.permissions)}>{x.element}</ProtectedRoute>
                            ) : (
                              x.element
                            )
                          }
                        />
                      ))}
                    </Routes>
                  </Suspense>
                  <SettingsPanel />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  ) : (
    <></>
  );
};

export default AppRoutes;
