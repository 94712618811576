import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import configureStore from "lib/reduxConfig";
import { ToastContainer } from "react-toastify";
// import { Web3Provider } from "@ethersproject/providers";
// import { Web3ReactProvider } from "@web3-react/core";
//----------------------------------------------------------------------- CSS IMPORTS
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";

//  ----------------------------------------- REACT QUERY OPTION ----------------------------------------- //

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
//  ----------------------------------------- WEB3 WRAPPER ----------------------------------------- //
const getLibrary = (provider) => {
  // const library = new Web3Provider(provider);
  // library.pollingInterval = 12000;
  // return library;
};

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/*<Web3ReactProvider getLibrary={getLibrary}>*/}
          <App />
          <ToastContainer rtl={false} position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick={true} pauseOnHover={true} draggable={true} progress={undefined} />
        {/*</Web3ReactProvider>*/}
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
);

serviceWorker.unregister();
