import { toast } from "react-toastify";
// const Msg = ({ closeToast, toastProps }) => (
//     <div>
//       Lorem ipsum dolor {toastProps.position}
//       <button>Retry</button>
//       <button onClick={closeToast}>Close</button>
//     </div>
//   )

const toastify = {
  info: ({ title, ...other }) =>
    toast.info(title, {
      // position: "top-right",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      ...other,
    }),
  error: ({ title, ...other }) =>
    toast.error(title, {
      ...other,
    }),
  success: ({ title, ...other }) =>
    toast.success(title, {
      // position: "top-right",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      ...other,
    }),
  warn: ({ title, ...other }) =>
    toast.warn(title, {
      ...other,
    }),
  // loading: ({ title, ...other }) => toast.loading("Please wait...", {
  //     ...other
  // }),
  update: ({ title, id, type, isLoading, ...other }) =>
    toast.update(id, {
      render: title,
      isLoading,
      type,
      ...other,
    }),
};
export default toastify;
