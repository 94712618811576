import { io } from 'socket.io-client';
import development from "configs/development.json";
import production from "configs/production.json";
import { getAccessToken } from 'utils/cookies';

const environment = process.env.REACT_APP_APP_STAGE === "production" ? production : development;

export const panelSocket = io(environment.SOCKET_URL,{
    reconnection:true,
    autoConnect:true,
    extraHeaders: {
        auth: getAccessToken()
    }
});