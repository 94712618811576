import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

import { setFunc } from "./operation";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BREADCRUMBS_SET:
      return setFunc(state, action);
    default:
      return state;
  }
};

export default reducer;
