import initialState from "./initialState";

export const requestFunc = (state, { queryOptions }) => ({
  ...state,
  isFetching: true,
  queryOptions,
});

export const receiveFunc = (state, { data }) => {
  return {
    ...state,
    isFetching: false,
    errorMessage: null,
    data,
    // ...(state.data ? { data: { ...state.data, ...data } } : { data })
  };
};

export const changeInfoFunc = (state, { params }) => ({
  ...state,
  isFetching: false,
  ...params,
});

export const failureFunc = (state, { errorMessage }) => ({
  ...state,
  isFetching: false,
  errorMessage,
});

export const initialFunc = () => {
  return { ...initialState };
};
