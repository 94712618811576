import React, { memo, useEffect, useRef } from "react";
// import Icon from "components/Icon";
// import styles from "./FindMe.module.sass";
import useOnScreen from "hooks/useOnScreen";

const FindMe = ({ callback, margin = "0px" }) => {
  const viewMeRef = useRef();

  const nextPage = useOnScreen(viewMeRef, margin);

  useEffect(() => {
    if (nextPage) callback(nextPage)
  }, [nextPage]);

  return (
    <div ref={viewMeRef} className="pb-1"></div>
  );
};

export default memo(FindMe);
