import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

const FieldSelect = ({ name, control, label, valid, register, errors, require, options, setState, ...other }) => {
  let newRegister = register ? register(name) : {};

  return (
    <Form.Group className="form-group ">
      <div className={"d-flex justify-content-between"}>
        <label htmlFor={name}>
          {label}
          {require ? " *" : ""}
        </label>
        <div className={`error-text ${errors[name]}`}>{errors[name]?.message}</div>
      </div>
      <Controller
        control={control}
        ref={newRegister}
        name={name}
        render={({ field: { onChange, value } }) => {
          function handleChange(params) {
            onChange(params);
            setState?.(params);
          }
          // console.log({ onChange, value, options },'');
          return <Select className="custom-selector panelCustomSelectBox" placeholder={label} options={options} value={value} onChange={handleChange} {...other} />;
        }}
      />
    </Form.Group>
  );
};
export default FieldSelect;
