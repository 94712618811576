import React, { Children, PropTypes } from "react";
import { Fragment } from "react";
import { Dropdown } from 'react-bootstrap';


const DropDownBootstrap = ({ data, title, subtitle, value, click, color, ...p }) => {
  // console.log({ disableLarger });

  return (
    <Dropdown {...p}>
      <Dropdown.Toggle className="limit-dropDown mr-3 btn-sm" variant={color}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropDown-menu-custom">
        {subtitle ? <Dropdown.Header header>{subtitle}</Dropdown.Header> : ""}
        {Children.toArray(
          data.map((x) => {
            return (
              <Dropdown.Item
                key={x.value}
                className="dropDown-item-custom mt-2"
                active={value === x.value}
                onClick={() => click(x)}
              >
                {x.label}
              </Dropdown.Item>
            );
          })
        )}
      </Dropdown.Menu>
    </Dropdown>
  );


};
// DropDownBootstrap.propTypes = {
//     data: PropTypes.array,
//     title: PropTypes.string,
//     subtitle: PropTypes.string,
//     value: PropTypes.string,
//     color: PropTypes.string,
//     disableLarger: PropTypes.string,
//     click: PropTypes.func,
// };
export default DropDownBootstrap;
