import { delay, fork, take, call, put, cancel, all, takeEvery, select } from "redux-saga/effects";

import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import actionTypes from "redux/store/socket/actionTypes";
import actions from "redux/store/socket/action";

// import { QueryClient } from "react-query";
import development from "configs/development.json";
import production from "configs/production.json";
import { getAccessToken } from 'utils/cookies';

const environment = process.env.REACT_APP_APP_STAGE === "production" ? production : development;

export function connect(token) {
  // console.log({ token });
  const socketConnection = io(environment.SOCKET_URL, {
    reconnection: true,
    autoConnect: true,
    extraHeaders: {
      auth: token
    }
  });

  return new Promise((resolve) => {
    socketConnection.on("connect", () => {
      console.log("CONNECT", socketConnection.connected);
      resolve(socketConnection);
    });
    socketConnection.on("disconnect", (data) => {
      console.log("DISCONNECTED");
    });
    socketConnection.on("reconnect", (data) => {
      console.log("RECONNECT");
    });
  });
}

// function subscribe(socket) {
//     return eventChannel((emit) => {
//         console.log({ emit });

//         const handler = (data) => {
//             console.log("Data in emitter ->>>>>>>", data);
//             emit(data);
//         };

//         socket.on("connect", handler.bind(null, "Connected!"));
//         socket.on("welcome", handler);
//         // socket.on('disconnect', handler);

//         return () => {
//             socket.off("welcome", handler);
//         };
//     });
// }
function subscribe(socket) {
  return eventChannel((emit) => {
    // console.log({ emit });

    const handler = (data) => {
      console.log("Data in emitter ->>>>>>>", data);
      emit(data);
    };

    socket.on("connect", handler.bind(null, "Connected!"));
    socket.on("welcome", handler);
    socket.on('disconnect', handler);

    return () => {
      socket.off("welcome", handler);
      socket.off("disconnect", handler);
      socket.off("serverStatus", handler);
    };
  });
}

export function* socketConnectSaga(data) {
  // console.log("socket initial ->>>>>>>", data);
  const stateAccessToken = yield select(state => state.accessToken);
  const token = stateAccessToken?.data //getAccessToken();
  const socket = yield call(connect, token);

  // console.log({ socket });

  if (socket) {
    yield put(actions.receive(socket));
  }

  const channel = yield call(subscribe, socket);

  // console.log({ channel });
  try {
    while (true) {
      let event = yield take(channel);

      console.log({ event });
    }
  } catch (error) {
    console.log("Error in saga chanel ->>>>>>>", error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.REQUEST, socketConnectSaga)]);
}
