

const initialState = {
    isFetching: false,
    errorMessage: null,
    // // demo
    //     data: {
    //       "refreshToken": "",
    //       "accessToken": "",
    //       "user": {
    //         type:"",
    //         fullName: "",
    //         email: "",
    //         password: "",
    //         role: ""
    //       },
    //       "permissions": []
    //     },
 
}

export default initialState;