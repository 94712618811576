export const USER_LOGIN = 'USER_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CHANGE_USER = 'CHANGE_USER';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_INITIAL = 'USER_INITIAL';
export const USER_RECEIVE = 'USER_RECEIVE';

export const USER_SET_TOKEN = 'USER_SET_TOKEN';
export const USER_CHANGE_INFO = 'USER_CHANGE_INFO';
