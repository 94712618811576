import React, { Component, useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import action from "redux/store/admin/action";
import { PLACEHOLDER_IMAGE } from "enumerations";
import logo from "assets/images/logo.png";
// ------------------------------------ WEB3 IMPORTS------------------------------------
import { chooseNetwork } from "enumerations/network";
import { changNetwork } from "lib/web3Func";
import { isMobile } from "react-device-detect";
import { connectWallet } from "enumerations/connectWallet";
import { cookie_names } from "enumerations";
import nookies from "nookies";
import { bsc, injected, WalletConnect, trustConnect } from "lib/connectors";
// import { useWeb3React } from "@web3-react/core";
import toastify from "lib/toastify";
import Connected from "./Connected";
import routes from "operations/routing/routes";

const Navbar = () => {
  //  ----------------------------------------- STATES/VARIABLES ----------------------------------------- //
  const [walletChose, setWalletChose] = useState(0);
  const [visibleConnectModal, setVisibleConnectModal] = useState(false);
  const [visibleNetworkModal, setVisibleNetworkModal] = useState(false);
  const [isNetwork, setIsNetwork] = useState();

  const currentAdmin = useSelector((state) => state.admin.data);
  const breadcrumbsRedux = useSelector((state) => state.breadcrumbs);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  //  ----------------------------------------- HANDLERS ----------------------------------------- //
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  const handleLogOut = () => {
    dispatch(action.logout());
    navigation(routes.login);
  };
  const handleChangePassword = () => {
    navigation(routes.adminInfo);
  };

  // ------------------------------------------------- Connect wallet
  // const context = useWeb3React();
  // const { connector, library, chainId, account, activate, deactivate, active, error } = context;
  // const network = useSelector((state) => state.network);

  //  ----------------------------------------- WEB3/WALLET ----------------------------------------- //
  // useEffect(() => {
  //   if (network) setIsNetwork(network);
  // }, [network]);

  // useEffect(() => {
  //   if (!account) return;
  //   if (!window.ethereum) throw new Error("No crypto wallet found");
  // }, [account]);

  // ----------------------------------------- CONNECT ----------------------------------------- //
  async function connect(wallet) {
    const activeWallet = wallet === "metamask" ? injected : wallet === "binance" ? bsc : wallet === "walletConnect" ? WalletConnect : wallet === "trustWallet" ? trustConnect : injected;

    console.log({ wallet, activeWallet });

    if (window.ethereum) {
      try {
        // const connecting = await activate(activeWallet);

        nookies.set(null, cookie_names.clickedWallet, wallet, { path: "/" });
        handleCloseConnectModal();
      } catch (error) {
        nookies.destroy(null, cookie_names.clickedWallet, { path: "/" });
        toastify.error({ title: "Please connect to your wallet" });
        console.log({ error }, "catchErr");
      }
    } else {
      wallet === "metamask"
        ? isMobile
          ? window.open("https://metamask.app.link/dapp/diolaunch.io/", "_blank")
          : window.open("https://metamask.io", "_blank")
        : wallet === "binance"
          ? window.open("https://www.binance.com/en/wallet-direct", "_blank")
          : window.open("https://trustwallet.com/", "_blank");
    }
  }

  // ----------------------------------------- DISCONNECT ----------------------------------------- //
  async function disconnect() {
    console.log("disconnect");
    // alert()
    try {
      // deactivate(injected);
      nookies.destroy(null, cookie_names.account, { path: "/" });
      toastify.success({ title: "Metamask Disconnected !" });
      // console.log("disconnect2");
    } catch (ex) {
      console.log({ ex });
    }
  }

  //  ----------------------------------------- CHANGE NETWORK ----------------------------------------- //
  const handleChangNetwork = async (params) => {
    changNetwork(params);
    // if (!account) connect();
    handleCloseNetworkModal(false);
  };

  const accountCookie = nookies.get(null)?.[cookie_names.account];
  // let isConnect = !((!accountCookie || !account) && !active);

  //  ----------------------------------------- MODAL HANDLERS ----------------------------------------- //
  const handleCloseConnectModal = () => setVisibleConnectModal(false);
  // -------------------------------------------------
  const handleOpenConnectModal = () => setVisibleConnectModal(true);
  // -------------------------------------------------
  const handleCloseNetworkModal = () => setVisibleNetworkModal(false);
  // -------------------------------------------------
  const handleOpenNetworkModal = () => setVisibleNetworkModal(true);
  //  ----------------------------------------- LOGS ----------------------------------------- //
  // console.log({ breadcrumbsRedux }, "bread");

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          {/* <button className="navbar-toggler navbar-toggler align-self-center order-2 shadow-none" type="button" onClick={() => document.body.classList.toggle("sidebar-icon-only")}>
            <span className="mdi mdi-menu"></span>
          </button> */}
          {/* <Link className="navbar-brand brand-logo order-1 d-flex align-items-center gap-1" to="/"> */}
          <Link className="d-flex align-items-center gap-1" to="/">
            <img src={logo} alt="logo" style={{width:35}}/>
            {/* <div className="h3 font-weight-bold mb-0">Chat</div> */}
          </Link>
          {/* <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={logo} alt="logo" />
        </Link> */}
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <div className="search-field d-none d-md-block">
            <div className="d-flex align-items-center h-100">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {breadcrumbsRedux?.map((breadcrumb, index) =>
                    breadcrumb.link ? (
                      <li className="breadcrumb-item text-capitalize">
                        <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
                      </li>
                    ) : (
                      <li className="breadcrumb-item active text-capitalize" aria-current="page">
                        {breadcrumb.title}
                      </li>
                    )
                  )}
                </ol>
              </nav>
            </div>
            {/* <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects"/>
              </div>
            </form> */}
          </div>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">
                  {/* <div className="nav-profile-img">
                    <img src={currentAdmin?.user?.image?.completedUrl || PLACEHOLDER_IMAGE} alt="user" />
                    <span className="availability-status online"></span>
                  </div> */}
                  <div className="nav-profile-text">{currentAdmin?.name || currentAdmin?.email || "Account"}</div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  {/* <Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()}>
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  Activity Log
                </Dropdown.Item> */}
                  <Dropdown.Item onClick={handleChangePassword}>
                    <i className="mdi mdi-key-variant mr-2 text-primary"></i>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogOut}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/*{!isConnect ? (*/}
            {false ? (
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                    <div className="nav-profile-img">
                      <i className="mdi mdi-wallet text-dark mr-2" style={{ fontSize: "20px" }} />
                    </div>
                    <div className="nav-profile-text">Connect</div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="navbar-dropdown">
                    {connectWallet.map(({ image, title, type }) => {
                      return (
                        <Dropdown.Item onClick={() => connect(type)} key={`${title}_wallets`} className="d-flex align-items-center">
                          <img className="img-fluid mr-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                          {title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : (
              <>
                {/* <li className="nav-item"> */}
                {/*<Connected {...{ library, account, chainId, disconnect }} />*/}
                {/* </li> */}

              </>
            )}
            {/*{isConnect && (*/}
            {false && (
              <li className="nav-item">
                <Dropdown align={"end"}>
                  <Dropdown.Toggle className="nav-link">
                    <div className="nav-profile-img">
                      <img className="img-fluid mr-2" style={{ width: "32px", height: "32px" }} src={isNetwork?.image} alt={isNetwork?.title} />
                    </div>
                    <div className="nav-profile-text">{isNetwork?.title}</div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="navbar-dropdown" style={{ minWidth: "max-content" }}>
                    <Dropdown.ItemText>Mainnet</Dropdown.ItemText>
                    <Dropdown.Divider />
                    {chooseNetwork.global.map(({ image, title, network }) => {
                      // let active = chainId === network.chainId;
                      let active = false;
                      return (
                        <Dropdown.Item className={`d-flex align-items-center ${active && "bg-primary text-light rounded"}`} key={title} onClick={() => !active && handleChangNetwork(network)}>
                          <img className="img-fluid mr-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                          {title}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.ItemText>Testnet</Dropdown.ItemText>
                    <Dropdown.Divider />
                    {chooseNetwork.testnet.map(({ image, title, network }) => {
                      // let active = chainId === network.chainId;
                      let active = false;
                      return (
                        <Dropdown.Item className={`d-flex align-items-center ${active && "bg-primary text-light rounded"}`} key={title} onClick={() => !active && handleChangNetwork(network)}>
                          <img className="img-fluid mr-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                          {title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}

            {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-email-outline"></i>
                  <span className="count-symbol bg-warning"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <h6 className="p-3 mb-0"><Trans>Messages</Trans></h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("assets/images/faces/face4.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><Trans>Mark send you a message</Trans></h6>
                      <p className="text-gray mb-0">
                        1 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("assets/images/faces/face2.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><Trans>Cregh send you a message</Trans></h6>
                      <p className="text-gray mb-0">
                        15 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("assets/images/faces/face3.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><Trans>Profile picture updated</Trans></h6>
                      <p className="text-gray mb-0">
                        18 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer">4 <Trans>new messages</Trans></h6>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count-symbol bg-danger"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0"><Trans>Notifications</Trans></h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="mdi mdi-calendar"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1"><Trans>Event today</Trans></h6>
                      <p className="text-gray ellipsis mb-0">
                      <Trans>Just a reminder that you have an event today</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1"><Trans>Settings</Trans></h6>
                      <p className="text-gray ellipsis mb-0">
                      <Trans>Update dashboard</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="mdi mdi-link-variant"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1"><Trans>Launch Admin</Trans></h6>
                      <p className="text-gray ellipsis mb-0">
                      <Trans>New admin wow</Trans>!
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer"><Trans>See all notifications</Trans></h6>
                </Dropdown.Menu>
              </Dropdown>
            </li> 

            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
                <i className="mdi mdi-power"></i>
              </a>
            </li>
            <li className="nav-item nav-settings d-none d-lg-block">
              <button type="button" className="nav-link border-0" onClick={ toggleRightSidebar} >
                <i className="mdi mdi-format-line-spacing"></i>
              </button>
            </li>
            */}
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      {/* <Modal show={visibleConnectModal} onHide={handleCloseConnectModal}>
        <Modal.Header closeButton>
          <Modal.Title>Choose your wallet:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            {connectWallet.map(({ image, title, type }) => {
              return (
                <div className={`d-flex flex-column align-items-center px-3 py-1 cursor-pointer`} key={title} onClick={() => connect(type)}>
                  <img className="img-fluid mt-4 mb-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                  <div className="">{title}</div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal show={visibleNetworkModal} onHide={handleCloseNetworkModal}>
        <Modal.Header closeButton>
          <Modal.Title>Choose the network:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            {chooseNetwork.global.map(({ image, title, network }) => {
              let active = chainId === network.chainId;
              return (
                <div
                  className={`d-flex flex-column align-items-center px-3 py-1 cursor-pointer ${active && "bg-primary text-light rounded"}`}
                  key={title}
                  onClick={() => !active && handleChangNetwork(network)}
                >
                  <img className="img-fluid mt-4 mb-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                  <div className="">{title}</div>
                </div>
              );
            })}
          </div>
          <div className="col-12 mt-5 mb-3">TESNET</div>
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            {chooseNetwork.testnet.map(({ image, title, network }) => {
              let active = chainId === network.chainId;
              return (
                <div
                  className={`d-flex flex-column align-items-center px-3 py-1 cursor-pointer ${active && "bg-primary text-light rounded"}`}
                  key={title}
                  onClick={() => !active && handleChangNetwork(network)}
                >
                  <img className="img-fluid mb-2" style={{ width: "32px", height: "32px" }} src={image} alt={title} />
                  <div className="">{title}</div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Navbar;
