import initialState from './initialState';

export const request = (state, { params }) => ({
    ...state,
    isFetching: true,
    params,
});

export const receive = (state, { data }) => ({
    ...state,
    isFetching: false,
    errorMessage: null,
    data
});

export const failure = (state, { errorMessage }) => ({
    ...state,
    isFetching: false,
    errorMessage,
});


export const verifyData = (state, { verifyData }) => {
    let date = new Date();
    return ({
        ...state,
        isFetching: false,
        verifyData,
        startDate: date.getTime()
        // {
        //     start: date.getTime(),
        //     end: date.getTime() + 120
        // }
    })
};

export const initial = () => ({ ...initialState });