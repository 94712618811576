import { all, call, put, takeEvery, select } from "@redux-saga/core/effects";
// import action from "../store/admin/action";
import action from "redux/store/admin/action";
import actionAccessToken from "redux/store/accessToken/action";
import actionRefreshToken from "redux/store/refreshToken/action";
import actionSocket from "redux/store/socket/action";
import * as actionTypes from "redux/store/admin/actionTypes";
import { changeError } from "utils/errors";
import network from "lib/network";
import api from "operations/network/api";

// use for demo

let response = {
    data: {
        user: {
            fullName: "string",
            email: "string",
            password: "string",
            role: "admin"
        },
        permissions: []
    },
    message: "done"
}


function* getSaga() {
    try {
        // const res = response //yield call(network.get, { url: api.user.current });
        console.log("start me")
        const res = yield call(network.get, { url: api.current });
        console.log({ res }, "me res");
        yield put(action.receive(res.data?.data));
    } catch (error) {
        console.log({ error }, error?.response?.status);
        if (error?.response?.status == 401) {
            // yield delay(2000);
            yield put(action.logout());
        }
        // setTimeout(() => {
        //     getSaga();
        // }, 2000);
        // yield put(action.failure(changeError(error?.response)));
        // yield delay(2000);
        // yield put(action.initial());
    }
}

export function* logoutSaga() {
    console.log("logout saga");
    const stateSocket = yield select(state => state.socket);
    stateSocket?.data?.disconnect()
    yield put(actionAccessToken.initial());
    yield put(actionRefreshToken.initial());

    yield put(actionSocket.initial());
    yield put(action.initial());
}

function* rootSaga() {
    // if (window.location.pathname !== "/login")
    yield all([takeEvery(actionTypes.USER_LOGOUT, logoutSaga), takeEvery(actionTypes.USER_REQUEST, getSaga)]);
}

export default rootSaga;
