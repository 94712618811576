import { yupResolver } from "@hookform/resolvers/yup";
import AlertComponent from "components/AlertComponent";
import FieldText from "components/FieldText";
import Loading from "components/Loading";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import action from "redux/store/admin/action";
import { useMutationCustom } from "utils/useMutationCustom";
import { fieldNames, validation } from "./validation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import { toast } from "react-toastify";
import { uploadFile } from "containers/files/api";


const ChangePassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admin.data);

  const [ImagePrv, setImagePrv] = useState();
  const [ImageFile, setImageFile] = useState();

  const updating = async (params) => await axiosClient().put(api["user"].base + `/${currentAdmin._id || currentAdmin._id}`, params);

  // Breadcrumbs
  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard },
    {
      title: "Admin Info",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    // toastify.success({ title: "Please Login with new data !" });
    // dispatch(action.logout());
    // navigate(routes.login);
    navigate(routes.dashboard);
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: updating,
    invalidQuery: `login`,
    onSuccess: onSuccessMutating,
  });

  const handleImage = (e, callback) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    console.log({ file });
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log({ reader });
      setImageFile(file);
      setImagePrv(reader.result);
      callback(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onSubmit = async () => {
    const values = getValues();

    let dataItem = {}

    if (ImageFile) {
      toast.info("please wait to upload your avatar ...")
      let formData = new FormData();
      formData.append("file", ImageFile);
      formData.append("userId", currentAdmin._id || currentAdmin._id);
      await uploadFile(formData).then(({ data }) => {
        toast.success("upload successfully")
        dataItem["avatar"] = data?.data?._id
      })
    }

    dataItem["name"] = values[fieldNames.name];
    
    if (values[fieldNames.newPassword].trim() !== "") {
      
      dataItem["password"] = values[fieldNames.newPassword];

    }

    console.log({ dataItem }, "dataItem");
    mutate(dataItem);
  };

  useEffect(() => {
    setValue(fieldNames.name, currentAdmin.name);
    setValue(fieldNames.image, currentAdmin.avatar || "");
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [])

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-6 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo">
              <h3>Your Info</h3>
            </div>
            <Form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              {/* <AlertComponent error={state.errorMessage} /> */}

              <div className="item mb-4">
                <div
                  className="category"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Avatar *{errors.image && <p className="error">{errors?.image?.message}</p>}
                </div>
                <Controller
                  name={fieldNames.image}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <label
                      className="file"
                      style={{
                        backgroundImage: `url(${value})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                        width: "150px",
                        height: "150px",
                      }}
                      htmlFor="uploadAvatar"
                    >
                      <input className="load" type="file" accept=".png,.jpg,.jpeg,.webp,.gif" id={"uploadAvatar"} onChange={(e) => handleImage(e, onChange)} />
                      <div className={`cover ${value && "active"}`}>
                        {value ? (
                          ""
                        ) : (
                          <>
                            {/* <div className="icon">
                                                    <Icon name="upload-file" size="24" />
                                                </div> */}
                            <div className="format">Your Avatar</div>
                          </>
                        )}
                      </div>
                    </label>
                  )}
                />
              </div>

              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.name,
                  register,
                  type: "text",
                  label: "Name",
                  placeholder: "Name",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />
              {/* <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.oldPassword,
                  register,
                  type: "password",
                  label: "Old Password",
                  placeholder: "Old Password",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              /> */}
              <FieldText
                // require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.newPassword,
                  register,
                  type: "password",
                  label: "New Password",
                  placeholder: "New Password",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />

              <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">{isLoading ? <Loading /> : "Done"}</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
