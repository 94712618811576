import * as yup from "yup";

export const fieldNames = {
    text: "text",
    language: "language",
    translatedText: "translatedText",
};

export const validation = (text) =>
    yup.object().shape({
        ...(!text && { [fieldNames.text]: yup.string().required().label("text") }),
        [fieldNames.language]: yup.object().required().label("language"),
        // [fieldNames.translatedText]: yup.object().required().label("translatedText"),
    })

