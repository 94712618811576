import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "redux/rootReducer";
import rootSaga from "redux/rootSaga";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = ({ ...state }) => {
  const { admin, ...isState } = state;
  try {
    const serializedState = JSON.stringify(isState);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

const bindMiddleware = (middleware) => {
  if (process?.env?.REACT_APP_APP_STAGE !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
}; //for redux devTools

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const localStorageState = loadState();
  // const localStorageState = JSON.parse(localStorage.getItem('reduxState'))
  const persistedState = localStorageState ? { ...localStorageState, ...initialState } : initialState;

  const store = createStore(rootReducer(), persistedState, bindMiddleware([sagaMiddleware]));
  store.subscribe((e) => {
    let state = store.getState();
    saveState({
      ...state,
    });
    // localStorage.setItem('reduxState', JSON.stringify(store.getState()))
    // persist your state
  });
  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
