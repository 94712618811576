export default {
  home: `/home`,
  login: `/login`,
  verify: `/verify`,
  dashboard: "/dashboard",
  adminInfo: "admin-info",
  file: {
    base: `/file`,
    list: `/file/list`,
    add: `/file/add`,
    edit: `/file/edit`,
  },

  ticket: {
    base: "/ticket",
    all: "/ticket/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    show: "/show",
  },

  user: {
    base: "/user",
    all: "/user/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  agency: {
    base: "/user/agency",
    all: "/user/agency/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  agent: {
    base: "/user/agent",
    all: "/user/agent/*",
    list: "/list",
    add: "/add",
  },

  ai: {
    base: "/ai",
    all: "/ai/*",

    character: {
      base: "/ai/character",
      all: "/ai/character/*",
      list: "/list",
      add : "/add",
      edit : "/edit",
      import : "/import"
    },
    category: {
      base: "/ai/category",
      all: "/ai/category/*",
      list: "/list",
      add : "/add",
      edit : "/edit",
    },
    setting: {
      base: "/ai/setting",
      all: "/ai/setting/*",

      general: {
        base: "/ai/setting/general",
        all: "/ai/setting/general/*",
        list: "/list",
        add: "/add",
      },
      advanced: {
        base: "/ai/setting/advanced",
        all: "/ai/setting/advanced/*",
        list: "/list",
        add: "/add",
      },
    },
  },

  product: {
    base: "/market/product",
    all: "/market/product/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    transaction: {
      list: "/transaction/list"
    }
  },

  plan: {
    base: "/market/plan",
    all: "/market/plan/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  subscription: {
    base: "/market/subscription",
    all: "/market/subscription/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    show: "/show",
  },

  transaction: {
    base: "/market/transaction",
    all: "/market/transaction/*",
    list: "/list",
    show: "/show",
  },

  chat: {
    base: "/chat",
    all: "/chat/*",
    list: "/list",
    chat: "/chat",
    // edit: "/edit",
  },

  settings: {
    base: "/system/settings",
    all: "/system/settings/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  interest: {
    base: "/interest",
    all: "/interest/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  promotion: {
    base: "/market/promotion",
    all: "/market/promotion/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },


  notification: {
    base: "/notification",
    all: "/notification/*",
    list: "/list",
    add: "/add"
  },

  faq: {
    base: "/faq",
    all: "/faq/*",
    list: "/list",
    add: "/add",
    edit: "/edit"
  },

  activity: {
    base: "/activity",
    all: "/activity/*",
    list: "/list",
    show: "/show"
  },

  call: {
    base: "/call",
    all: "/call/*",
    list: "/list",
    show: "/show"
  },

  payment: {
    base: "/finance/payment",
    all: "/finance/payment/*",
    list: "/list",
    add: "/add",
    edit: "/edit"
  },

  redeem: {
    base: "/finance/redeem",
    all: "/finance/redeem/*",
    list: "/list",
    show: "/show"
  },

  media: {
    base: "/media",
    all: "/media/*",
    list: "/list",
    file: {
      base: "/media/file",
      all: "/media/file/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    gallery: {
      base: "/media/gallery",
      all: "/media/gallery/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
  },

  system: {
    base: "/system",

    permission: {
      base: "/system/permission",
      all: "/system/permission/*",
      list: "/list",
      add: "/add",
      edit: "/edit"
    },

  },
  role: {
    base: "/system/role",
    all: "/system/role/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  newsletter: {
    base: "/newsletter",
    all: "/newsletter/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  blog: {
    base: "/blog",
    all: "/blog/*",
    post: {
      base: "/blog/post",
      all: "/blog/post/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    category: {
      base: "/blog/category",
      all: "/blog/category/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    tag: {
      base: "/blog/tag",
      all: "/blog/tag/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
  },
  category: {
    base: "/category",
    all: "/category/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  auctionManagement: {
    base: "/auctionManagement",
    all: "/auctionManagement/*",
    list: {
      base: "/auctionManagement/list",
      all: "/auctionManagement/list/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    offer: {
      base: "/auctionManagement/offer",
      all: "/auctionManagement/offer/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
  },
  marketplace: {
    base: "/marketplace",
    all: "/marketplace/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  collection: {
    base: "/collection",
    all: "/collection/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  manager: {
    base: "/manager",
    all: "/manager/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
};
