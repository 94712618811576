import actionTypes from "../actionTypes"


export default {
    request: (params) => ({
        type: actionTypes.VERIFY_CODE_REQUEST,
        params,
    }),
    receive: (data) => ({
        type: actionTypes.VERIFY_CODE_RECEIVE,
        data,
    }),
    initial: () => ({
        type: actionTypes.VERIFY_CODE_INITIAL,
    }),
    failure: (errorMessage) => ({
        type: actionTypes.VERIFY_CODE_FAILURE,
        errorMessage
    }),
    verifyData: (verifyData) => ({
        type: actionTypes.VERIFY_CODE_VERIFY_DATA,
        verifyData
    }),
}