import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name : "name",
  image: "image",
  oldPassword: "oldPassword",
  newPassword: "newPassword",
};

export const validation = yup.object().shape({
  [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour("Name")).label("Name"),
  [fieldNames.image]: yup.string().label("Image"),
  // [fieldNames.oldPassword]: yup.string().required(ERRORS.pleaseEnterYour("old password")).label("old password"),
  [fieldNames.newPassword]: yup.string().label("new password"),
});
