import actionTypes from "./actionTypes"


const actions = {
    request(queryOptions) {
        return {
            type: actionTypes.REQUEST,
            queryOptions,
        }
    },

    receive(data) {
        return {
            type: actionTypes.RECEIVE,
            data,
        }
    },

    initial() {
        return {
            type: actionTypes.INITIAL,
        }
    }
}

export default actions
