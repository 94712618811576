
import { ERRORS } from "enumerations";
import * as yup from 'yup';

export const fieldNames = {
    email: 'email',
    password: 'password',
    rememberMe: 'rememberMe'
};


export const validation = yup.object().shape({
    [fieldNames.email]: yup
        .string()
        .email(ERRORS.isRequire('email'))
        .required(ERRORS.pleaseEnterYour('email'))
        .label('email'),
    [fieldNames.password]: yup
        .string()
        .required(ERRORS.pleaseEnterYour('password'))
        .label('password')
})





