import actionTypes from "./actionTypes"

export default {
    request: (params, ctx) => ({
        type: actionTypes.REFRESH_TOKEN_REQUEST,
        params,
        ctx
    }),
    receive: (data, ctx) => ({
        type: actionTypes.REFRESH_TOKEN_RECEIVE,
        data,
        ctx
    }),
    initial: (ctx) => ({
        type: actionTypes.REFRESH_TOKEN_INITIAL,
        ctx
    }),
    failure: (errorMessage) => ({
        type: actionTypes.REFRESH_TOKEN_FAILURE,
        errorMessage
    }),
    checkRefreshTokenTimeout: (expirationTime, ctx) => ({
        type: actionTypes.REFRESH_TOKEN_CHECK_TIMEOUT,
        expirationTime,
        ctx
    }),
    refreshTokenCheck: (ctx, ...other) => ({
        type: actionTypes.REFRESH_TOKEN_CHECK,
        ctx,
        ...other
    }),
}