import React, { useState } from "react";
import { Form } from "react-bootstrap";

const FieldText = ({ name, label, valid, register, errors, require, type, error, refrence, parentClass, ...other }) => {
  const [show, setShow] = useState(false);
  let newRegister = register ? register(name) : {};
  return (
    <Form.Group className={`form-group ${parentClass}`}>
      <div className={"d-flex justify-content-between"}>
        {label ? (
          <label htmlFor={name}>
            {label}
            {require ? " *" : ""}
          </label>
        ) : (
          ""
        )}
      </div>
      <div className="input-wrapper">
        <Form.Control id={name} name={name} type={type === "password" ? (show ? "text" : "password") : type} placeholder={label} ref={refrence} {...other} {...newRegister} />
        {type === "password" && <i className={`${show ? "mdi mdi-eye-off" : "mdi mdi-eye"} cursor-pointer text-muted eye-wrapper`} onClick={() => setShow(!show)}></i>}
      </div>
      <div className={`error-text mt-1 ${errors?.[name]}`}>{errors?.[name]?.message}</div>
    </Form.Group>
  );
};
export default FieldText;
