import React, { Component, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { AccordionContext, Dropdown } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import routes from "operations/routing/routes";
import { PERMISSIONS } from "enumerations";
import { useSelector } from "react-redux";
import { findElementsInTwoArrays } from "utils/findElementsInTwoArrays";
import { findUserAccessPermission } from "utils/index";

const Sidebar = () => {
  const [state, setState] = useState({});

  const [SubMenuShowArr, setSubMenuShowArr] = useState({
    title: "",
    submenu: [],
  });

  let location = useLocation();

  const sidebar = [
    {
      icon: <i className="mdi mdi-home-outline menu-icon"></i>,
      mainTitle: "Dashboard",
      title: "Dashboard",
      link: routes.dashboard,
      // permissions: [PERMISSIONS.USER],
    },
    {
      icon: <i className="mdi mdi-chat-outline menu-icon"></i>,
      title: "Chat",
      link: routes.chat.base,
      // permissions: [PERMISSIONS.ADMINISTRATOR],
    }
  ];

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState((p) => ({ ...p, [menuState]: false }));
    } else if (Object.keys(state).length === 0) {
      setState((p) => ({ ...p, [menuState]: true }));
    } else {
      Object.keys(state).forEach((i) => {
        setState((p) => ({ ...p, [i]: false }));
      });
      setState((p) => ({ ...p, [menuState]: true }));
    }
  }

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState((p) => ({ ...p, [i]: false }));
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState((p) => ({ ...p, [obj.state]: true }));
      }
    });

    // if(location.pathname === "/dashboard" || location.pathname === "/"){
    //   setSubMenuShowArr({
    //     title : sidebar[0].title,
    //     submenu : sidebar[0].data
    //   })
    // }

    let matchSidebar = false ;

    for (let i = 0; i < sidebar.length; i++) {
      const element = sidebar[i];
      if (element.urlName) {
        if (location.pathname.search(element.urlName) > -1) {
          matchSidebar = true;
          if (element.data) {

            setSubMenuShowArr({
              title: element.title,
              submenu: element.data
            })

            if (!document.body.classList.contains('sidebar-show-submenu')) {
              document.body.classList.add("sidebar-show-submenu")
            }

          } else {
            document.body.classList.remove("sidebar-show-submenu")
          }

          break;
        }
      }
      if (location.pathname.search(element.link) > -1) {
        matchSidebar = true;
        if (element.data) {

          setSubMenuShowArr({
            title: element.title,
            submenu: element.data
          })

          if (!document.body.classList.contains('sidebar-show-submenu')) {
            document.body.classList.add("sidebar-show-submenu")
          }

        } else {
          document.body.classList.remove("sidebar-show-submenu")
        }

        break;
      }

    }

    if(!matchSidebar){
      document.body.classList.remove("sidebar-show-submenu")
    }

  }

  function isPathActive(path) {
    return location.pathname.startsWith(path);
  }

  // useEffect(() => {
  //   onRouteChanged();
  // }, [location]);

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    const tooltipSidemenu = document.querySelector(".hoverSidebarMenuTitle");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        let tooltipSidemenuRect = el.getBoundingClientRect();

        if (!tooltipSidemenu.classList.contains("show")) {
          tooltipSidemenu.classList.add("show");
          tooltipSidemenu.innerText = el.childNodes[0].childNodes[1].textContent;
          tooltipSidemenu.style.cssText = `top : ${tooltipSidemenuRect.top}px`;
        }
      });
      el.addEventListener("mouseout", function () {
        if (tooltipSidemenu.classList.contains("show")) {
          tooltipSidemenu.classList.remove("show");
        }
      });
    });
  }, [location]);

  function ContextAwareToggle({ children, eventKey, callback, icon, dropdown }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div className={isCurrentEventKey ? "nav-link menu-expanded" : "nav-link"} onClick={decoratedOnClick} data-toggle="Accordion.collapse">
        {icon}
        <span className="menu-title">{children}</span>
        {dropdown ? <i className="menu-arrow mdi mdi-chevron-right"></i> : ""}
      </div>
    );
  }

  const adminState = useSelector((state) => state.admin);

  const handleControlSideBar = (mainMenu, submenu) => {
    if (!document.body.classList.contains('sidebar-show-submenu')) {
      document.body.classList.add("sidebar-show-submenu")
    }

    if (!submenu || submenu?.length === 0) {
      document.body.classList.remove("sidebar-show-submenu")
    }

    console.log({
      mainSubmenu: submenu
    })
    setSubMenuShowArr({
      title: mainMenu || "",
      submenu: submenu || [],
    })

  }

  return (
    <>
      <div className="position-relative" style={{
        height:"100vh"
      }}>
        <div className="hoverSidebarMenuTitle"></div>
        <nav className="sidebar sidebar-offcanvas sidebar-fixed" id="sidebar">
          {/* <Accordion defaultActiveKey="0"> */}
            <ul className="nav">
              {/* <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/dashboard">
                  <i className="mdi mdi-home menu-icon"></i>
                  <span className="menu-title"><Trans>Dashboard</Trans></span>
                </Link>
              </li> */}
              {sidebar.map((x, i) => {
                let titleWithSpace;
                // return !x.permissions || findUserAccessPermission(adminState.data.permissions, x.permissions ? x.permissions : []) ? (
                return (adminState?.data?.role?.name === "administrator" || adminState?.data?.role?.name === "superadmin") ? (
                  x.link ? (
                    <li key={x.title}>
                      <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}
                      // onClick={() => handleControlSideBar(x.title, x?.data)}
                      >
                        <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                          {x.mainTitle ? x.mainTitle : x.title}
                        </ContextAwareToggle>
                        {x?.data ? (
                          <Accordion.Collapse eventKey={i}>
                            <ul className="nav flex-column sub-menu">
                              {x.data.map((y) => {
                                return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                  <li key={y.title} className="nav-item">
                                    <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                      {y.title}
                                    </NavLink>
                                  </li>
                                ) : (
                                  ""
                                );
                              })}
                            </ul>
                          </Accordion.Collapse>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </li>
                  ) : (
                    <li key={x.title} className={`nav-item nav-link hasSubMenu ${window.location.toString().includes(x?.urlName) && "activeChildren"}`}
                    // onClick={() => handleControlSideBar(x.title, x?.data)}
                    >
                      {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                      <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir">
                        {x.mainTitle ? x.mainTitle : x.title}
                      </ContextAwareToggle>
                      {x?.data ? (
                        <Accordion.Collapse eventKey={i}>
                          <ul className="nav flex-column sub-menu">
                            {x.data.map((y) => {
                              return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                <li key={y.title} className="nav-item">
                                  <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                    {y.title}
                                  </NavLink>
                                </li>
                              ) : (
                                ""
                              );
                            })}
                          </ul>
                        </Accordion.Collapse>
                      ) : (
                        ""
                      )}
                      {/* </NavLink> */}
                    </li>
                  )
                ) : !x.permissions || findUserAccessPermission(adminState?.data?.role?.permissions, x.permissions ? x.permissions : []) ? (
                  x.link ? (
                    <li key={x.title}>
                      <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}
                      // onClick={() => handleControlSideBar(x.title, x?.data)}
                      >
                        <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                          {x.mainTitle ? x.mainTitle : x.title}
                        </ContextAwareToggle>
                        {x?.data ? (
                          <Accordion.Collapse eventKey={i}>
                            <ul className="nav flex-column sub-menu">
                              {x.data.map((y) => {
                                return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                  <li key={y.title} className="nav-item">
                                    <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                      {y.title}
                                    </NavLink>
                                  </li>
                                ) : (
                                  ""
                                );
                              })}
                            </ul>
                          </Accordion.Collapse>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </li>
                  ) : (
                    <li key={x.title} className={`nav-item nav-link ${window.location.toString().includes(x?.urlName) && "activeChildren"}`}
                    // onClick={() => handleControlSideBar(x.title, x?.data)}
                    >
                      {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                      <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir">
                        {x.mainTitle ? x.mainTitle : x.title}
                      </ContextAwareToggle>
                      {x?.data ? (
                        <Accordion.Collapse eventKey={i}>
                          <ul className="nav flex-column sub-menu">
                            {x.data.map((y) => {
                              return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                <li key={y.title} className="nav-item">
                                  <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                    {y.title}
                                  </NavLink>
                                </li>
                              ) : (
                                ""
                              );
                            })}
                          </ul>
                        </Accordion.Collapse>
                      ) : (
                        ""
                      )}
                      {/* </NavLink> */}
                    </li>
                  )
                ) : (
                  ""
                );
              })}

            </ul>
          {/* </Accordion> */}
        </nav>

        <div className="showSubmenuMainBox">
          {typeof SubMenuShowArr.title === "string" ?
            <>
              <div className="showSubmenuMainHeader">{SubMenuShowArr.title}</div>
              <div className="showSubmenuMainBody">
                <Accordion defaultActiveKey="-1">
                  {SubMenuShowArr.submenu.map((x, i) => {
                    let titleWithSpace;
                    // return !x.permissions || findUserAccessPermission(adminState.data.permissions, x.permissions ? x.permissions : []) ? (
                    return (adminState?.data?.role?.name === "administrator" || adminState?.data?.role?.name === "superadmin") ? (
                      x.link ? (
                        <li key={x.title}>
                          <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}>
                            <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                              {x.mainTitle ? x.mainTitle : x.title}
                            </ContextAwareToggle>
                            {x?.data ? (
                              <Accordion.Collapse eventKey={i}>
                                <ul className="nav flex-column sub-menu">
                                  {x.data.map((y) => {
                                    return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                      <li key={y.title} className="nav-item">

                                        <NavLink
                                          className={"nav-link"}
                                          activeClassName={"nav-link active"}
                                          to={y.link}
                                        >
                                          {y.icon}
                                          <span>
                                            {y.title}
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </Accordion.Collapse>
                            ) : (
                              ""
                            )}
                          </NavLink>
                        </li>
                      ) : (
                        <li key={x.title} className={`nav-item nav-link hasSubMenu ${window.location.toString().includes(x?.urlName) && "activeChildren"}`}
                        // onClick={() => handleControlSideBar(x?.data)}
                        >
                          {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                          <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir">
                            {x.mainTitle ? x.mainTitle : x.title}
                          </ContextAwareToggle>
                          {x?.data ? (
                            <Accordion.Collapse eventKey={i}>
                              <ul className="nav flex-column sub-menu">
                                {x.data.map((y) => {
                                  return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                    <li key={y.title} className="nav-item">
                                      <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link} end>
                                        {y.title}
                                      </NavLink>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              </ul>
                            </Accordion.Collapse>
                          ) : (
                            ""
                          )}
                          {/* </NavLink> */}
                        </li>
                      )
                    ) : !x.permissions || findUserAccessPermission(adminState?.data?.role?.permissions, x.permissions ? x.permissions : []) ? (
                      x.link ? (
                        <li key={x.title}>
                          <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}>
                            <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                              {x.mainTitle ? x.mainTitle : x.title}
                            </ContextAwareToggle>
                            {x?.data ? (
                              <Accordion.Collapse eventKey={i}>
                                <ul className="nav flex-column sub-menu">
                                  {x.data.map((y) => {
                                    return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                      <li key={y.title} className="nav-item">
                                        <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                          {y.title}
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </Accordion.Collapse>
                            ) : (
                              ""
                            )}
                          </NavLink>
                        </li>
                      ) : (
                        <li key={x.title} className={`nav-item nav-link ${window.location.toString().includes(x?.urlName) && "activeChildren"}`}>
                          {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                          <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir">
                            {x.mainTitle ? x.mainTitle : x.title}
                          </ContextAwareToggle>
                          {x?.data ? (
                            <Accordion.Collapse eventKey={i}>
                              <ul className="nav flex-column sub-menu">
                                {x.data.map((y) => {
                                  return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                    <li key={y.title} className="nav-item">
                                      <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                        {y.title}
                                      </NavLink>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              </ul>
                            </Accordion.Collapse>
                          ) : (
                            ""
                          )}
                          {/* </NavLink> */}
                        </li>
                      )
                    ) : (
                      ""
                    );
                  })}
                </Accordion>
              </div>
            </>
            :
            <>
              {SubMenuShowArr.title.map((titleSubMenu, mainIndex) =>
                <>
                  <div className="showSubmenuMainHeader">{titleSubMenu}</div>
                  <div className="showSubmenuMainBody">
                    <Accordion defaultActiveKey="-1">
                      {SubMenuShowArr.submenu[mainIndex].map((x, i) => {
                        let titleWithSpace;
                        // return !x.permissions || findUserAccessPermission(adminState.data.permissions, x.permissions ? x.permissions : []) ? (
                        return (adminState?.data?.role?.name === "administrator" || adminState?.data?.role?.name === "superadmin") ? (
                          x.link ? (
                            <li key={x.title}>
                              <NavLink className={
                                  ({isActive}) => (
                                    x.link === (location.pathname + location.search) ? "active " : 
                                    x.link.split("?")[1]?.length > 3 ? (decodeURI(location.search).indexOf(x.link.split("?")[1]) > -1 && location.pathname === x.link.split("?")[0] ? "active " : ""): "") 
                                    + "nav-item nav-link"
                                  } 
                                  activeClassName={"active"} to={x.link} end
                                >
                                <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                                  {x.title}
                                </ContextAwareToggle>
                                {x?.data ? (
                                  <Accordion.Collapse eventKey={i}>
                                    <ul className="nav flex-column sub-menu">
                                      {x.data.map((y) => {
                                        return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                          <li key={y.title} className="nav-item">

                                            <NavLink
                                              className={"nav-link"}
                                              activeClassName={"nav-link active"}
                                              to={y.link}
                                            >
                                              {y.icon}
                                              <span>
                                                {y.title}
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </ul>
                                  </Accordion.Collapse>
                                ) : (
                                  ""
                                )}
                              </NavLink>
                            </li>
                          ) : (
                            <li key={x.title} className={`nav-item nav-link hasSubMenu ${window.location.toString().includes(x?.urlName) && "activeChildren"}`}>
                              {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                              <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir">
                                {x.title}
                              </ContextAwareToggle>
                              {x?.data ? (
                                <Accordion.Collapse eventKey={i}>
                                  <ul className="nav flex-column sub-menu">
                                    {x.data.map((y) => {
                                      return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                        <li key={y.title} className="nav-item">
                                          <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link} end>
                                            {y.title}
                                          </NavLink>
                                        </li>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  </ul>
                                </Accordion.Collapse>
                              ) : (
                                ""
                              )}
                              {/* </NavLink> */}
                            </li>
                          )
                        ) : !x.permissions || findUserAccessPermission(adminState?.data?.role?.permissions, x.permissions ? x.permissions : []) ? (
                          x.link ? (
                            <li key={x.title}>
                              <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link} end>
                                <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                                  {x.title}
                                </ContextAwareToggle>
                                {x?.data ? (
                                  <Accordion.Collapse eventKey={i}>
                                    <ul className="nav flex-column sub-menu">
                                      {x.data.map((y) => {
                                        return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                          <li key={y.title} className="nav-item">
                                            <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                              {y.title}
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </ul>
                                  </Accordion.Collapse>
                                ) : (
                                  ""
                                )}
                              </NavLink>
                            </li>
                          ) : (
                            <li key={x.title} className={`nav-item nav-link ${window.location.toString().includes(x?.urlName) && "activeChildren"}`} >
                              {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                              <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data} className="kir" >
                                {x.title}
                              </ContextAwareToggle>
                              {x?.data ? (
                                <Accordion.Collapse eventKey={i}>
                                  <ul className="nav flex-column sub-menu">
                                    {x.data.map((y) => {
                                      return !y.permissions || findUserAccessPermission(adminState.data.role.permissions, y.permissions ? y.permissions : []) ? (
                                        <li key={y.title} className="nav-item">
                                          <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                            {y.title}
                                          </NavLink>
                                        </li>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  </ul>
                                </Accordion.Collapse>
                              ) : (
                                ""
                              )}
                              {/* </NavLink> */}
                            </li>
                          )
                        ) : (
                          ""
                        );
                      })}
                    </Accordion>
                  </div>
                </>
              )}
            </>

          }
        </div>
      </div>

    </>
  );
};

export default Sidebar;
