import axiosConfig from "lib/axiosConfig";
import { getAccessToken, getRefreshToken, removeTokens } from "./cookies";
import { setCookie } from "nookies";
import { delay, put } from "redux-saga/effects";
import actionAccessToken from "redux/store/accessToken/action";
import actionRefreshToken from "redux/store/refreshToken/action";
import actionUser from "redux/store/admin/action";
import action from "containers/Verify/redux/store/action";
import { useNavigate } from "react-router-dom";
import configureStore from "lib/reduxConfig";
import { toast } from "react-toastify";





export const findUserAccessPermission = (userPermission, routePermission) => {
    if (userPermission) {
        return userPermission.some((item) => routePermission.includes(item.type));
    }
    return false;
}

export const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("text copied successfully!")
  };

export const handleRefreshAccessToken = () => {
    // const navigation = useNavigate();
    let token = getRefreshToken();

    const refreshReqStatus = localStorage.getItem("refreshStatus");

    console.log("fffff")

    if (token && !refreshReqStatus) {
        localStorage.setItem("refreshStatus" , true)
        axiosConfig().post("/auth/refresh-auth", {
            token: token
        }).then(({ data }) => {
            localStorage.removeItem("refreshStatus")
            console.log({ data }, "-> handleRefreshAccessToken")
            let accessToken = data?.data?.access?.token;
            let refreshToken = data?.data?.refresh?.token;
            const dateNow = new Date();
            let exTimeAccess = new Date(data?.data?.access?.expires);
            let exTimeRefresh = new Date(data?.data?.refresh?.expires);

            console.log({ exTimeAccess, exTimeRefresh, dateNow, refreshToken });

            setCookie(null, "refreshToken", refreshToken, { maxAge: exTimeRefresh, path: "/" });
            setCookie(null, "accessToken", accessToken, { path: "/" });

            put(actionAccessToken.receive(accessToken));
            put(actionRefreshToken.receive(refreshToken));

            let stateLocal = localStorage.getItem("state");
            if(stateLocal){

                stateLocal = JSON.parse(stateLocal);
                stateLocal.accessToken["data"] = accessToken;
                stateLocal.refreshToken["data"] = refreshToken;
            }
            // console.log(stateLocal)

            
            // put(actionUser.request());

            // const store = configureStore();


            // store.dispatch(actionUser.request());

            put(action.receive(data));
            
            
            localStorage.setItem("state" , JSON.stringify(stateLocal));
            delay(2000);
            // put(action.initial());

            if(window.location.pathname === "/dashboard" || window.location.pathname === "/"){
                
                window.location.reload();
            }
            // navigation("/dashboard")
            
        }).catch((err) => {
            localStorage.removeItem("refreshStatus")
            removeTokens();
            console.log({ err }, "refreshToken My");
            window.location.href="/login";
        })

    }

}