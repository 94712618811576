
import actionTypes from "../actionTypes";
import initialState from "./initialState"
import { failure, initial, receive, request, verifyData } from "./operation"



const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_CODE_REQUEST:
      return request(state, action)
    case actionTypes.VERIFY_CODE_RECEIVE:
      return receive(state, action)
    case actionTypes.VERIFY_CODE_FAILURE:
      return failure(state, action)
    case actionTypes.VERIFY_CODE_INITIAL:
      return initial(state, action)
    case actionTypes.VERIFY_CODE_VERIFY_DATA:
      return verifyData(state, action)
    default:
      return state
  }
}

export default reducer;