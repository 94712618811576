import { formatDistanceToNowStrict } from "date-fns";

const Statistics = ({ title, data }) => {
    return (
        <>

            <div className="row">
                <div className="col-12 stretch-card">
                    <div className="card">
                    <div className="card-body">
                        <h4 className="card-title mb-2 font-weight-bold">{title}</h4>
                        <p className="card-description h6 text-primary">Count by status</p>
                        <div className="row">
                            {data?.map(item =>
                                <>
                                    <div className="d-flex flex-column card-widget col-md-4">
                                        <div className="d-flex flex-column">
                                            <i className={`${item?.icon} icon-md text-${item.color}`}></i>
                                        </div>
                                        <div className="d-flex flex-column mt-3 mb-3">
                                            <h1 className="font-weight-bold mb-0">{item?.count}</h1>
                                            <p className="mb-0 text-muted">{item?.title}</p>
                                        </div>

                                        <div className="">
                                            <span class={`badge badge-light-${item.badge}`}>
                                                <i class="mdi mdi-arrow-up mr-1"></i>
                                                2.1%
                                            </span>
                                        </div>

                                    </div>

                                </>
                                
                            )}
                            
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Statistics;