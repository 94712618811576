import nookies from "nookies";
import initialState from "./initialState";

export const requestFunc = (state, { queryOptions }) => ({
  ...state,
  isFetching: true,
  queryOptions,
});

export const receiveFunc = (state, { data }) => ({
  ...state,
  isFetching: false,
  errorMessage: null,
  splash: false,
  data,
});

export const failureFunc = (state, { errorMessage }) => ({
  ...state,
  isFetching: false,
  splash: false,
  errorMessage,
});

export const initialFunc = (ctx) => {
  console.log("refresh toke initial");
  nookies.destroy(ctx, "refreshToken");
  return { ...initialState, splash: false, };
};
